// 把一个类型下的所有url接口放入这一个文件
import { appUrl } from '@/api/baseUrl'
const prefix = '/operation/customerAdmin'
// let operationCenterUrl = '/operationApi'
export default {
    customerSplittingList: appUrl + prefix + '/customerQueryOrder',
    isHasManager: appUrl + '/operation/customer/findCustomerByPage',
    detachManager: appUrl + prefix + '/addMorderCusomterAdmin',
    addManager: appUrl + prefix + '/addCustomerAdmin',
    managerList: appUrl + prefix + '/selectAllAdmin',
    deleteCustomerAdmin: appUrl + prefix + '/deleteCustomerAdmin',
    exchangeCustomerAdmin: appUrl + prefix + '/exchangeCustomerAdmin',
    deleteMoreCustomerAdmin: appUrl + prefix + '/deleteMoreCustomerAdmin',
    exportCustomerAdmin: appUrl + prefix + '/exportCustomerAdmin',
    exportCustomerAdminErr: appUrl + prefix + '/exportCustomerAdminErr'
}
