import React, {
    // useState
} from 'react';
import {
    Switch,
    // ConfigProvider
} from 'antd';
import PropTypes from 'prop-types';
import YzhColorPicker from '@com/YzhColorPicker';
// import { initTheme, storage } from '@/utils'
import './theme.scss'
import { connect } from 'react-redux'
// const { themeConfig } = require('@/config')
//需要渲染什么数据
function mapStateToProps (state) {
    return {
        themeConfig: state.theme.themeConfig
    }
}
//需要触发什么行为
function mapDispatchToProps (dispatch) {
    return {
        setTheme: (val) => dispatch({
            type: 'setTheme',
            value: {
                ...val
            }
        }),
        onSwitchChange: (checked, name, state) => dispatch({
            type: 'setTheme',
            value: {
                ...state,
                [name]: name === 'tabsLayout' ? checked ? 'inline' : 'vertical' : checked
            }
        }),
        getTheme: () => dispatch({ type: 'getTheme' })
    }
}
const Theme = (props) => {
    const { setTheme, themeConfig, onSwitchChange } = props
    // console.log(themeConfig, 'primaryColor');
    return (
        <div className="theme-container">
            <ul className="img clearfix">
                {
                    themeConfig.defaults.map((el, key) => {
                        return <li className={themeConfig.id === el.id ? 'active img-item fl' : 'img-item fl'} onClick={() => setTheme({ ...themeConfig, ...el })} key={key}>
                            <img src={el.url} alt="" />
                        </li>
                    })
                }
            </ul>
            <div className="custom-color">
                <h3 className="custom-color-title">自定义主题</h3>
                <div className="custom-color-list">
                    <div className="custom-color-item">
                        <span>主题色：</span><YzhColorPicker color={themeConfig.theme.primaryColor} objKey={1} updateColor={(key, primaryColor) => {
                            themeConfig.theme.primaryColor !== primaryColor && setTheme({
                                ...themeConfig,
                                id: '',
                                theme: {
                                    ...themeConfig.theme,
                                    primaryColor
                                }
                            })
                        }} />
                    </div>
                    <div className="custom-color-item">
                        <span>次主题色：</span><YzhColorPicker color={themeConfig.theme.secondColor} objKey={2} updateColor={(key, color) => {
                            themeConfig.theme.secondColor !== color && setTheme({
                                ...themeConfig,
                                id: '',
                                theme: {
                                    ...themeConfig.theme,
                                    secondColor: color
                                }
                            })
                        }} />
                    </div>
                    <div className="custom-color-item">
                        <span> 主题背景色：</span><YzhColorPicker color={themeConfig.theme.bgColor} objKey={3} updateColor={(key, color) => {
                            themeConfig.theme.bgColor !== color && setTheme({
                                ...themeConfig,
                                id: '',
                                theme: {
                                    ...themeConfig.theme,
                                    bgColor: color
                                }
                            })
                        }} />
                    </div>
                </div>
            </div>
            <div className="others">
                <div className="others-item">
                    <span className="name">多页签：</span><Switch defaultChecked={themeConfig.showTabsBar} onChange={(val) => onSwitchChange(val, 'showTabsBar', themeConfig)} />
                </div>
                <div className="others-item">
                    <span className="name">页签布局：</span><Switch
                        checkedChildren="水平"
                        unCheckedChildren="垂直"
                        defaultChecked={themeConfig.tabsLayout === 'inline'}
                        onChange={(val) => onSwitchChange(val, 'tabsLayout', themeConfig)} />
                </div>
                <div className="others-item">
                    <span className="name">页脚：</span><Switch defaultChecked={themeConfig.showFooter} onChange={(val) => onSwitchChange(val, 'showFooter', themeConfig)} />
                </div>
                <div className="others-item">
                    <span className="name">刷新：</span><Switch defaultChecked={themeConfig.showRefresh} onChange={(val) => onSwitchChange(val, 'showRefresh', themeConfig)} />
                </div>
                <div className="others-item">
                    <span className="name">黑白模式：</span><Switch defaultChecked={themeConfig.isBlackModel} onChange={(val) => onSwitchChange(val, 'isBlackModel', themeConfig)} />
            </div>
            </div>
        </div>
    );
};
Theme.propTypes = {
    setTheme: PropTypes.func,
    themeConfig: PropTypes.shape({
        defaults: PropTypes.array,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        theme: PropTypes.shape({
            primaryColor: PropTypes.string,
            secondColor: PropTypes.string,
            bgColor: PropTypes.string,
        }),
        showTabsBar: PropTypes.bool,
        tabsLayout: PropTypes.string,
        showFooter: PropTypes.bool,
        showRefresh: PropTypes.bool,
    }),
    onSwitchChange: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Theme)