/**
 * @description 导出默认通用配置
 */
const antSetting = {
    // 表单公共配置
    tableConfig: {
        bordered: true,
        search: {
            labelWidth: 'auto',
            span: {
                xs: 24,
                sm: 24,
                md: 12,
                lg: 12,
                xl: 8,
                xxl: 6,
            },
            // span: {
            //     xs: 24,
            //     sm: 24,
            //     md: 12,
            //     lg: 8,
            //     xl: 4,
            //     xxl: 4,
            // },
            className: 'yzh-search-form',
            optionRender: (searchConfig, formProps, dom) => [
                ...dom.reverse(),
            ],
            searchText: '搜索'
        },
        columnConfig: {
            fieldProps: {
                allowClear: true,
                showSearch: true,
                // autoClearSearchValue: true,
                maxTagCount: 1,
                maxTagTextLength: 10,
                mode: 'multiple',
            },
        },
        tableAlertRender: false,
        editable: {
            type: 'multiple',
        },
        pagination: {
            pageSize: 20,
            simple: false,
            size: 'default',
            showTotal: total => `共 ${total} 条`,
            pageSizeOptions: [10, 20, 50, 100, 200],
            showQuickJumper: true,
        },
        rowSelection: {
            // checkStrictly: false,
            // hideSelectAll: true,
            // selections: [
            //     Table.SELECTION_ALL,
            //     Table.SELECTION_INVERT,
            //     Table.SELECTION_NONE,
            // ],
            columnWidth: 60,
            fixed: true,
        },
        className: 'yzh-table',
        tableClassName: "yzh-table-wrapper",
        dateFormatter: "",
        options: false
    },
    modalConfig: {
        title: '温馨提示',
        centered: true,
        closable: true,
        maskClosable: false,
    },
    loadingConfig: {
        spinning: true,
        size: 'middle',
        tip: '加载中...',
        delay: 100,
    },
    messageConfig: {
        duration: 2, // 持续时间
        maxCount: 1, // 最大显示数, 超过限制时，最早的消息会被自动关闭
        top: 20, // 到页面顶部距离
    },
    globalConfig: {
        // componentSize: 'middle', // 设置 antd 组件大小 small | middle | large
        input: {
            allowClear: true
        },
        form: {
            colon: true,
            labelAlign: 'left',
            name: 'yzh',
            size: 'large', // small | middle | large
            labelCol: { span: 18, offset: 2 },
            wrapperCol: { span: 18, offset: 2 },
            scrollToFirstError: true,
        },
        getPopupContainer: () => document.body, // 弹出框（Select, Tooltip, Menu 等等）渲染父节点，默认渲染到 body 上
        // csp: { nonce: 'ease' }, // { nonce: string }
        iconPrefixCls: 'anticon', // 设置图标统一样式前缀
        prefixCls: 'ant', // 设置统一样式前缀
        virtual: true,
        direction: 'ltr' // 设置文本展示方向
    }
}
export default antSetting
