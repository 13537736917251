// 把一个类型下的所有url接口放入这一个文件
import { baseUrl } from '@/api/baseUrl'
// const baseUrl = '/yd'
const prefix = '/admin'
// let operationCenterUrl = '/operationApi'
export default {
    add: baseUrl + prefix + '/addCategoryAdmin',
    delete: baseUrl + prefix + '/deleteCategoryAdmin',
    deleteMore: baseUrl + prefix + '/deleteMoreCategoryAdmin',
    change: baseUrl + prefix + '/exchangeCategoryAdmin',
    list: baseUrl + prefix + '/pageCategoryAdmin',
    export: baseUrl + prefix + '/exportCategoryAdmin',
    exportCategoryAdminErr: baseUrl + prefix + '/exportCategoryAdminErr', // 导出失败数据
}
