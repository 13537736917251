import React from 'react';
import { Upload, message } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import uploadUrls from '@/api/upload/urls'
import errorImage from '@/assets/images/placeholder.png'
import { storage } from '@/utils/storage'
const token = storage.get('token', 'cookie')
// function getBase64 (img, callback) {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result));
//     reader.readAsDataURL(img);
// }
class YzhUploadImage extends React.Component {
    state = {
        loading: false,
        text: this.props.text,
        hasError: false,
        imageUrl: this.props.value,
        type: '',
        size: {
            width: this.props.size && this.props.size.width || '',
            height: this.props.size && this.props.size.height || ''
        },
        fileType: this.props.fileType || 'jpeg,png'
    };
    //如果条件不存在必须要返回null
    static getDerivedStateFromProps (props, current_state) {
        if (props.value !== current_state.imageUrl) {
            return {
                imageUrl: current_state.hasError ? errorImage : props.value,
            }
        }
        return null
    }
    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file, 'info.file');
            if (info.file.response.success) {
                let imageUrl = info.file.response.data
                this.setState({
                    imageUrl,
                    hasError: false,
                    loading: false,
                })
                this.props.onChange && this.props.onChange(imageUrl)
            } else {
                this.setState({
                    imageUrl: '',
                    hasError: false,
                    loading: false,
                })
            }
        }
    };
    limitWidthHeight (file, maxWidth = 100, maxHeight = 100) {
        let isAllow = true
        let reader = new FileReader()
        return new Promise((resolve) => {
            reader.onload = function (e) {
                let image = new Image()
                image.src = e.target.result
                image.onload = function () {
                    let width = image.width
                    let height = image.height
                    isAllow = width === maxWidth && height === maxHeight
                    resolve(isAllow)
                }
            }
            reader.readAsDataURL(file)
        })
    }
    async beforeUpload (file, fileType, size = {}, limit = 2) {
        const isJpgOrPng = fileType.includes(file.type.split('/')[1]);
        if (!isJpgOrPng) {
            message.error(`只能上传 ${fileType.split(',').join('/').toUpperCase()} 格式的文件!`);
        }
        let isLimit = await this.limitWidthHeight(file, size.width, size.height)
        if (!isLimit) {
            if (size.width && size.height) {
                message.error(`上传的图片尺寸大小只能是 ${size.width + '*' + size.height} px!`);
            } else {
                isLimit = true
            }
        }
        const isLt2M = file.size / 1024 / 1024 < limit;
        if (!isLt2M) {
            message.error(`上传的图片不得大于 ${limit}MB!`);
        }
        return isJpgOrPng && isLt2M && isLimit;
    }
    render () {
        const { loading, imageUrl, text, size, fileType } = this.state
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined style={{ fontSize: 30, color: '#666' }} /> :
                    <PlusOutlined style={{ fontSize: 30, color: '#666' }} />}
                {text && <div style={{ marginTop: 8 }}>{text}</div>}
            </div>
        );
        return (
            <div className="yzh-upload row align-c">
                <Upload
                    name="file"
                    listType="picture-card"
                    style={{ overflow: 'hidden' }}
                    className="avatar-uploader col"
                    showUploadList={false}
                    headers={{
                        'Authorization': 'Bearer ' + token
                    }}
                    action={uploadUrls.yzhFile}
                    beforeUpload={(file) => this.beforeUpload(file, fileType, size)}
                    onChange={this.handleChange}
                >
                    {imageUrl ? <img
                        src={imageUrl}
                        alt="avatar"
                        onError={(e) => {
                            this.setState({ imageUrl: errorImage, hasError: true })
                        }}
                        style={{ width: '100px', hieght: '100px', overflow: 'hidden' }} /> : uploadButton}
                </Upload>
                {size.width && size.height && <span
                    style={{ color: '#999' }}
                    className="tips col-2">
                    图片只能上传{size.width}*{size.height}px的{fileType.split(',').join('/').toUpperCase()}格式
                </span>}
            </div>
        );
    }
}
YzhUploadImage.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.string]),
    size: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    fileType: PropTypes.oneOfType([PropTypes.string]),
    onChange: PropTypes.oneOfType([PropTypes.func]),
}
export default YzhUploadImage