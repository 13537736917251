// 有些接口需要在header中添加token或是其他，可以按如下配置
import axios from '@/api/axios'
import urls from './urls'
const header = {
    'Content-Type': 'multipart/form-data'
}

export default {
    header,
    queryByPage (data, code) { // 承运商对应列表
        return axios.get(urls.queryByPage, {
            params: data,
            headers: {
                OperationCode: code || 'shipment_mapping_list'
            }
        })
    },
    kuaidi100ShipmentList (data, code) { // 承运商下拉框
        return axios.get(urls.kuaidi100ShipmentList, {
            params: data,
            headers: {
                OperationCode: code || 'shipment_mapping_addOrEdit'
            }
        })
    },
    addOrEdit (params, code) { // 承运商对应列表新增编辑
        return axios.post(urls.addOrEdit, params, {
            headers: {
                OperationCode: code || 'shipment_mapping_addOrEdit'
            }
        })
    }
}
