import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import {
    Checkbox
    // Tag, Space,
    // Menu, Dropdown
} from 'antd';
import ProTable, {
    // ProColumns,
    // ActionType,
    // TableDropdown
} from '@ant-design/pro-table';
import { antConfig } from '@/config'
import YzhProgressDialog from '@com/YzhProgressDialog'
import { VirtualTable } from './VirtualTable'
import './style.scss'
const { tableConfig } = antConfig
class YzhTable extends React.Component {
    state = {
        progressDialogRef: React.createRef(),
        identifyKey: '',
        progressText: '',
        selectAll: false,
        dataSource: [],
        selectedRows: [],
        selectedRowKeys: []
    }
    onSelect = (record, selected, selectedRows, nativeEvent) => {
        let rowKey = this.props.rowKey
        let selectedRowKeys = selected
            ? [...new Set([...this.state.selectedRowKeys, record[rowKey]])]
            : this.state.selectedRowKeys.filter((item) => item !== record[rowKey])
        let newSelectedRows = selected ? [...this.state.selectedRows, record]
            : this.state.selectedRows.filter((item) => item[rowKey] !== record[rowKey])
        this.setSelectedRows(selectedRowKeys, newSelectedRows)

    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        let rowKey = this.props.rowKey
        let changeRowKeys = changeRows.map(el => el[this.props.rowKey])
        let selectedRowKeys = selected
            ? [...new Set([...this.state.selectedRowKeys, ...changeRowKeys])]
            : this.state.selectedRowKeys.filter((item) => !new Set(changeRowKeys).has(item))
        let newSelectedRows = selected ? [...this.state.selectedRows, ...changeRows]
            : this.state.selectedRows.filter((item) => !new Set(changeRowKeys).has(item[rowKey]))
        this.setSelectedRows(selectedRowKeys, newSelectedRows)
    }
    //设置选择的行
    setSelectedRows = (selectedRowKeys, selectedRows, selectAll) => {
        selectAll = selectAll !== undefined ? selectAll : this.state.selectAll
        const { rowSelection } = this.props
        let newRowKeys = selectedRowKeys
        let newSelectedRows = selectedRows
        if (selectAll) {
            newRowKeys = Array.from(new Set([...this.state.selectedRowKeys, ...selectedRowKeys]))
            newSelectedRows = Array.from(new Set([...this.state.selectedRows, ...selectedRows]))
        }
        this.setState({
            selectAll,
            selectedRowKeys: newRowKeys,
            selectedRows: newSelectedRows
        })
        rowSelection && rowSelection.onChange(newRowKeys, newSelectedRows, selectAll)
    }
    onExport = (params, call) => {
        let { key, text } = params
        key = key || params
        this.setState({
            identifyKey: key || '',
            progressText: text || '导出'
        }, () => {
            this.state.progressDialogRef.current.start(call)
        })
    }
    componentWillUnmount () {
        this.setState = (state, callback) => {
            return;
        };
    }
    render () {
        const {
            dataSource = [],
            identifyKey,
            progressDialogRef,
            selectAll,
            selectedRows,
            selectedRowKeys } = this.state
        const { rowSelection, scroll, tableStyle, themeConfig, virtual, ...propsConfig } = this.props
        // console.log(rowSelection, 'scroll');
        return (<React.Fragment>
            {virtual ? <VirtualTable
                tableStyle={{ minHeight: scroll.y - 390, ...tableStyle }}
                {...tableConfig}
                {...propsConfig}
                scroll={{
                    ...scroll,
                    y: themeConfig.showFooter ? scroll.y - 120 : scroll.y
                }}
                dataSource={dataSource}
                tableClassName={`${tableConfig.tableClassName} ${rowSelection && 'selection-table'}`}
                onLoad={(dataSource) => {
                    this.setState({
                        dataSource
                    })
                    selectAll && this.setSelectedRows(dataSource.map(el => el[propsConfig.rowKey]), dataSource)
                    !selectAll && this.setSelectedRows(selectedRowKeys, selectedRows)
                }}
                onReset={() => {
                    this.setState({
                        selectAll: false,
                        selectedRowKeys: []
                    })
                    propsConfig.onReset && propsConfig.onReset()
                }}
                search={propsConfig.search !== false && {
                    ...tableConfig.search,
                    ...propsConfig.search,
                    // collapseRender: false,
                    className: 'yzh-table-search',
                }}
                footer={rowSelection && dataSource.length ? () => (<Checkbox
                    key="checkAll"
                    disabled={!dataSource.length}
                    onChange={(e) => {
                        let checked = e.target.checked
                        this.setSelectedRows(checked ? dataSource.map(el => el[propsConfig.rowKey]) : [], checked ? dataSource : [], checked)
                    }} checked={selectAll}>全选筛选条件下的所有数据</Checkbox>) : (null)
                }
                rowSelection={rowSelection ? {
                    ...tableConfig.rowSelection,
                    ...rowSelection,
                    selectedRowKeys,
                    onSelect: this.onSelect,
                    onSelectAll: this.onSelectAll,
                } : false}
            /> : <ProTable
                tableStyle={{ minHeight: scroll.y - 430, ...tableStyle }}
                {...tableConfig}
                {...propsConfig}
                scroll={{
                    ...scroll,
                    y: themeConfig.showFooter ? scroll.y - 120 : scroll.y
                }}
                tableClassName={`${tableConfig.tableClassName} ${rowSelection && 'selection-table'}`}
                onLoad={(dataSource) => {
                    this.setState({
                        dataSource
                    })
                    selectAll && this.setSelectedRows(dataSource.map(el => el[propsConfig.rowKey]), dataSource)
                    !selectAll && this.setSelectedRows(selectedRowKeys, selectedRows)
                }}
                onReset={() => {
                    this.setState({
                        selectAll: false,
                        selectedRowKeys: []
                    })
                    propsConfig.onReset && propsConfig.onReset()
                }}
                search={propsConfig.search !== false && {
                    ...tableConfig.search,
                    ...propsConfig.search,
                    // collapseRender: false,
                    collapsed: false,
                    className: 'yzh-table-search',
                }}
                footer={rowSelection && dataSource.length ? () => (<Checkbox
                    key="checkAll"
                    disabled={!dataSource.length}
                    onChange={(e) => {
                        let checked = e.target.checked
                        this.setSelectedRows(checked ? dataSource.map(el => el[propsConfig.rowKey]) : [], checked ? dataSource : [], checked)
                    }} checked={selectAll}>全选筛选条件下的所有数据</Checkbox>) : (null)
                }
                rowSelection={rowSelection ? {
                    ...tableConfig.rowSelection,
                    ...rowSelection,
                    selectedRowKeys,
                    onSelect: this.onSelect,
                    onSelectAll: this.onSelectAll,
                } : false}
            />}
            < YzhProgressDialog
                ref={progressDialogRef}
                text={this.state.progressText}
                onSuccess={() => {
                    this.setState({
                        identifyKey: ''
                    })
                }}
                identifyKey={identifyKey} />
        </React.Fragment >);
    }
}
YzhTable.propTypes = {
    style: PropTypes.object,
    rowKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
function mapStateToProps (state) {
    return {
        themeConfig: state.theme.themeConfig
    }
}
// 使用 connect 包裹后，子组件是函数创建的，需要添加 forwardRef 属性 处理组件函数报异常
export default connect(mapStateToProps, null, null, { forwardRef: true })(YzhTable)