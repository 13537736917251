import axios from '@/api/axios'
import urls from './urls'
import { appUrl } from '@/api/baseUrl'
import { storage } from '@/utils/storage'
// const appUrl = '/storage'
// const appUrl = 'http://yapi.yunzhonghe.com/mock/258'
const common = {
    orderGetDataAuthskip (params) {
        return axios.get(urls.orderGetDataAuthskip, {
            params
        })
    },
    // 商品品牌
    brandList (code) {
        return axios.get(urls.brandList, {
            headers: {
                OperationCode: code || 'stock_list'
            }
        })
    },
    // 商品分类
    categoryList (code) {
        return axios.get(urls.categoryList, {
            headers: {
                OperationCode: code || 'user_category_admin'
            }
        })
    },
    // 库存中心商品来源
    getSourceFromData (code) {
        return axios.get(urls.getSourceFromData, {
            headers: {
                OperationCode: code || 'stock_list'
            }
        })
    },
    // 进度条
    loadingProgressAuthskip (params) {
        return axios.get(urls.loadingProgressAuthskip, { params })
    },
    // 获取仓库列表
    getWarehouseList (code) {
        return axios.get(urls.getWarehouseList, {
            headers: {
                OperationCode: code || 'stock_list'
            }
        })
    },
    getOptions (api, params = {}) {
        if (api.url.includes('yapi')) {
            return axios.get(`${api.url}`, {
                params,
                headers: { OperationCode: api.operationCode }
            })
        }
        if (storage.get(`${api.url}`) && !params.refresh) {
            let result = storage.get(`${api.url}`)
            if (params && params.kwd && params['key']) {
                result = result.filter(el => el[params['key']].includes(params.kwd))
            }
            if (params && params.currentPage) {
                result = result.slice((params.currentPage - 1) * 20, 20 * params.currentPage)
            }
            // console.log(result, 'result')
            return Promise.resolve({
                success: true,
                result
            })
        }
        // params['key'] = params.kwd
        return new Promise((resolve, reject) => {
            axios.get(`${appUrl}${api.url}`, {
                params,
                headers: { OperationCode: api.operationCode }
            }).then(({ success, result }) => {
                if (result == null) {
                    resolve({
                        result: [],
                        success
                    })
                    return
                }
                if (result.page) {
                    resolve({
                        page: result.page,
                        result: (result[0] && result[0].dataList) || result.records || [],
                        success
                    })
                    return
                }
                result = (result[0] && result[0].dataList) || result
                storage.set(`${api.url}`, result, 10 * 1000)
                if (params && params.kwd && params['key']) {
                    result = result.filter(el => el[params['key']].includes(params.kwd))
                }
                if (params && params.currentPage) {
                    result = result.slice((params.currentPage - 1) * 20, 20 * params.currentPage)
                }
                resolve({
                    result,
                    success
                })
            }).catch(err => reject(err))
        })
    },
    // 获取所有地区
    getRegionData (params, code) {
        return axios.get(urls.regionData, {
            params,
            headers: { OperationCode: code || 'sell_regional' }
        })
    },
    // 获取导入错误的文件路径
    errorFileAuthskip (data, code) {
        return axios.get(urls.errorFileAuthskip, {
            params: data,
            headers: {
                OperationCode: code || 'order_manage'
            }
        })
    },
    departmentCombobox (data, code) {
        return axios.get(urls.departmentCombobox, {
            params: data,
            headers: {
                OperationCode: code || 'order_manage'
            }
        })
    },
    departmentSync (data, code) {
        return axios.post(urls.departmentSync, data, {
            headers: {
                OperationCode: code || 'user_dept_add'
            }
        })
    },
    emplist (data, code) {
        return axios.get(urls.emplist, {
            params: data
        })
    },
    getSalerByDep (data, code) {
        return axios.get(urls.getSalerByDep, {
            params: data,
            headers: {
                OperationCode: code || 'user_center'
            }
        })
    },
    // 用户中心的进度条
    userProgressBar (params) {
        return axios.get(urls.userProgressBar, { params })
    },
}
export default common
