import React, { Component } from 'react';

class YzhFooter extends React.Component {
    render () {
        return <div style={{
            textAlign: 'center',
            height: 60,
            lineHeight: '60px',
            fontSize: 14,
            boxSizing: 'border-box',
            zIndex: 9,
            boxShadow: ' 0 -5px 10px 0 rgb(7 0 2 / 4%)',
            background: '#fff'
        }}>
            <p style={{ color: 'var(--primary-color)' }}>
                <span>Copyright © 2001-{new Date().getFullYear()}</span>
                <a href="https://www.yunzhonghe.com" target="_blank" style={{
                    marginLeft: 10
                }} rel="noreferrer">深圳市云中鹤科技股份有限公司</a>
            </p>
        </div>;
    }
}

export default YzhFooter;