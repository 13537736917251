import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
const YzhSkeleton = (props) => {
    const state = {
        loading: true,
        active: true,
        block: false,
        size: 'default',
        title: false,
        rows: 8,
        buttonShape: 'default',
        avatarShape: 'circle',
        ...props
    }
    const { active, size, buttonShape, block, title, rows } = state
    return (
        <div className="loading-skeleton" style={{ ...props.style, height: 280 }} >
            {buttonShape && <Skeleton.Button active={active} size={size} shape={buttonShape} block={block} />}
            {buttonShape && <br />}
            {buttonShape && <br />}
            <Skeleton active={active} title={title} paragraph={{ rows }} {...props}>
                {props.children}
            </Skeleton>
        </div>
    )
}
YzhSkeleton.propTypes = {
    style: PropTypes.object,
    loading: PropTypes.bool,
    active: PropTypes.bool,
    block: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.bool,
    rows: PropTypes.number,
    buttonShape: PropTypes.string,
    avatarShape: PropTypes.string,
};
export default YzhSkeleton