// 有些接口需要在header中添加token或是其他，可以按如下配置
import axios from '@/api/axios'
import urls from './urls'
import { permissionCodes as codes } from '@/routes/permission'
const header = {
    'Content-Type': 'multipart/form-data'
}

export default {
    header,
    supplierCustomerSplittingList (params, code) {
        return axios.post(urls.supplierCustomerSplittingList, params, {
            headers: {
                OperationCode: code || codes.admin.supplier.list
            }
        })
    },
    supplierManagerList (data, code) {
        return axios.get(urls.supplierManagerList, {
            params: data,
            headers: {
                OperationCode: code || codes.admin.supplier.list
            }
        })
    },
    supplierIsHasManager (params, code) {
        return axios.post(urls.supplierIsHasManager, params, {
            headers: {
                OperationCode: code || codes.admin.supplier.list
            }
        })
    },
    supplierDetachManager (params, code) {
        return axios.post(urls.supplierDetachManager, params, {
            headers: {
                OperationCode: code || codes.admin.supplier.update
            }
        })
    },
    supplierDeleteCustomerAdmin (params, code) {
        return axios.post(urls.supplierDeleteCustomerAdmin, params, {
            headers: {
                OperationCode: code || codes.admin.supplier.delete
            }
        })
    },
    supplierAddManager (params, code) {
        return axios.post(urls.supplierAddManager, params, {
            headers: {
                OperationCode: code || codes.admin.supplier.update
            }
        })
    },
    // 导出客户分单
    supplierExportCustomerAdmin (params, code) {
        return axios.post(urls.supplierExportCustomerAdmin, params, {
            headers: {
                OperationCode: code || codes.admin.supplier.export
            }
        })
    },
    deleteMoreSupplierAdmin (params, code) {
        return axios.post(urls.deleteMoreSupplierAdmin, params, {
            headers: {
                OperationCode: code || codes.admin.supplier.delete
            }
        })
    },
    exchangeSupplierAdmin (params, code) {
        return axios.post(urls.exchangeSupplierAdmin, params, {
            headers: {
                OperationCode: code || codes.admin.supplier.exchange
            }
        })
    },
    exportSupplierAdminErr (data, code) {
        return axios.get(urls.exportSupplierAdminErr, {
            params: data,
            headers: {
                OperationCode: code || codes.admin.supplier.list
            }
        })
    },
}
