import { asyncLoadComponent } from '@/routes/asyncLoadComponent'
import {
    AppstoreOutlined,
} from '@ant-design/icons';
import { permissionCodes as codes } from '../permission'
export default [
    {
        path: '/product',
        exact: false,
        auth: false,
        index: 4,
        pIndex: null,
        meta: {
            hideLayout: false,
            title: '商品中心',
            hideInMenu: true,
            roles: [codes.goods_center],
            icon: <AppstoreOutlined />
        },
        component: asyncLoadComponent(() => import('@/pages/microApps?app=product')),
    },
    {
        path: '/order',
        exact: false,
        auth: false,
        index: 4,
        pIndex: null,
        meta: {
            hideLayout: false,
            title: '订单中心',
            hideInMenu: true,
            roles: [codes.order_center],
            icon: <AppstoreOutlined />
        },
        component: asyncLoadComponent(() => import('@/pages/microApps?app=order')),
    },
    {
        path: '/stock',
        exact: false,
        auth: false,
        index: 4,
        pIndex: null,
        meta: {
            hideLayout: false,
            title: '库存管理',
            hideInMenu: true,
            roles: [codes.stock_center_manage],
            icon: <AppstoreOutlined />
        },
        component: asyncLoadComponent(() => import('@/pages/microApps?app=stock')),
    },
    {
        path: '/operation',
        exact: false,
        auth: false,
        index: 4,
        pIndex: null,
        meta: {
            hideLayout: false,
            title: '运营中心',
            roles: [codes.operation_center],
            hideInMenu: true,
            icon: <AppstoreOutlined />
        },
        component: asyncLoadComponent(() => import('@/pages/microApps?app=operation')),
    },
    {
        path: '/finance',
        exact: false,
        auth: false,
        index: 4,
        pIndex: null,
        meta: {
            hideLayout: false,
            title: '财务中心',
            roles: [codes.finance_center],
            hideInMenu: true,
            icon: <AppstoreOutlined />
        },
        component: asyncLoadComponent(() => import('@/pages/microApps?app=finance')),
    },
    {
        path: '/supplier',
        exact: false,
        auth: false,
        index: 4,
        pIndex: null,
        meta: {
            hideLayout: false,
            title: '供应商管理系统',
            roles: [codes.supplier_center],
            hideInMenu: true,
            icon: <AppstoreOutlined />
        },
        component: asyncLoadComponent(() => import('@/pages/microApps?app=supplier')),
    },
    {
        path: '/enterprise',
        exact: false,
        auth: false,
        index: 4,
        pIndex: null,
        meta: {
            hideLayout: false,
            title: '企业端',
            roles: [codes.goods_center],
            hideInMenu: true,
            icon: <AppstoreOutlined />
        },
        component: asyncLoadComponent(() => import('@/pages/microApps?app=enterprise')),
    },
]