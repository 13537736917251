import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Route, Switch } from 'react-router-dom'
import './AnimatedSwitch.scss'
// import {CacheRoute, CacheSwitch } from 'react-router-cache-route'
const AnimatedSwitch = props => {
    const { children } = props
    return (
        <Route
            render={({ location }) => {
                return (
                    <TransitionGroup className={props.className}>
                        <CSSTransition
                            key={location.key}
                            classNames={props.type || 'fade'}
                            timeout={props.duration || 500}
                        >
                            <Switch location={location}>
                                {children}
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                )
            }}
        />
    )
}
export default AnimatedSwitch