// 本地存储
import Cookies from 'js-cookie'
const { setting } = require('@/config')
export const storage = {
    get (key, type) {
        let data = ''
        if (setting.storage === 'cookie' || type === 'cookie') {
            data = Cookies.get(key)
            try {
                if (data && data !== null) {
                    return JSON.parse(data)
                }
            } catch (error) {
                return data
            }
            return
        }
        data = window[setting.storage].getItem(`${setting.appName}-${key}`)
        try {
            if (data && data !== null) {
                data = JSON.parse(data)
                if (data.expires < new Date().getTime()) {
                    return ''
                }
                return data.value
            }
        } catch (error) {
            return ''
        }
    },
    set (key, value, expires = setting.expires, type) {
        // 24 * 60 * 60 * 1000
        if (typeof (expires) === 'string') {
            type = expires
            expires = setting.expires
        }
        if (setting.storage === 'cookie' || type === 'cookie') {
            expires = new Date(new Date() * 1 + expires)
            Cookies.set(key, value, { expires })
            return
        }
        expires = new Date().getTime() + expires
        window[setting.storage].setItem(`${setting.appName}-${key}`, JSON.stringify({
            value,
            expires
        }))
    },
    remove (key, type) {
        if (setting.storage === 'cookie' || type === 'cookie') {
            Cookies.remove(key)
            return
        }
        window[setting.storage].removeItem(`${setting.appName}-${key}`)
    }
}