import './App.css';
import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom' // 引入路由组件
import {
    Modal,
    ConfigProvider,
} from 'antd';
import { connect } from 'react-redux'
// 国际化语言配置
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { apps } from '@/micro-app' // 微应用
import routes from '@/routes'
import { matchRouteByTree, initTheme, isLowerIEVersion } from './utils'
import { antConfig } from '@/config'
// import { AliveScope } from 'react-activation'
// 路由高阶组件
import FrontendAuth from '@/routes/FrontendAuth'
import BaseLayout from './layout'
import YzhEmpty from '@com/YzhEmpty'
//设置属性
window.microApp = true
const { globalConfig } = antConfig
moment.locale('zh-cn');
class App extends React.Component {
    state = {
        isShowBrowserTips: true
    }
    componentDidMount () {
        const { themeConfig } = this.props
        initTheme(themeConfig).then(config => {
            ConfigProvider.config({
                prefixCls: 'ant',
                theme: {
                    primaryColor: config.theme.primaryColor,
                },
                ...antConfig.modalConfig
            });
        })
    }
    render () {
        // console.log(isLowerIEVersion(), 'isLowerIEVersion');
        let isIE = isLowerIEVersion() && isLowerIEVersion() <= 11
        return (
            <ConfigProvider
                renderEmpty={YzhEmpty}
                {...globalConfig}
                locale={zhCN}>
                <Router className={'yzh-app-user'}>
                    {isIE && window.location.pathname !== '/browser' && this.state.isShowBrowserTips && <div className="yzh-ie-tips">
                        <div className="w1200 content">
                            <span className="text">微软已宣布停止IE浏览器的支持，可能会有安全风险，请更换浏览器获取更好的体验。</span>
                            <a href="/browser" className="down-btn">点我下载</a>
                            {/* <span className="close" onClick={() => this.setState({
                                isShowBrowserTips: false
                            })}>×</span> */}
                        </div>
                    </div>}
                    <Switch>
                        {routes.filter(el => el.meta && el.meta.hideLayout).map((v, i) => {
                            return <FrontendAuth exact routerConfig={v} path={v.path} key={i} />
                        })}
                        <Route path="/"
                            render={(props) => {
                                let pathname = props.history.location.pathname
                                const { routes: remoteRoutes = [] } = this.props.userInfo || []
                                const damycRoutes = [...routes, ...remoteRoutes].filter((el, index, self) => self.findIndex(val => val.path === el.path) === index)
                                const { existRoute, currentRoute } = matchRouteByTree([...damycRoutes], pathname, {children: 'children'})
                                for (const key in apps) {
                                    if (pathname.includes(apps[key].baseRoute))
                                        // 非严格匹配，/my-page/* 都将匹配到 MyPage 组件
                                        return <BaseLayout {...props} className="yzh-layout"></BaseLayout>
                                }
                                for (const key in apps) {
                                    if (pathname === apps[key].baseRoute)
                                        //  严格匹配
                                        return <BaseLayout {...props} className="yzh-layout"></BaseLayout>
                                }
                                return (
                                    // <BaseLayout {...props} className="yzh-layout"></BaseLayout>
                                    existRoute ?
                                        <BaseLayout {...props} className="yzh-layout"></BaseLayout>
                                        : <Redirect to="/404" />
                                )
                            }
                            } />
                    </Switch>
                </Router>
            </ConfigProvider>
        );
    }
}
function mapStateToProps (state) {
    return {
        themeConfig: state.theme.themeConfig,
        userInfo: state.app.userInfo,
    }
}
export default connect(mapStateToProps)(React.memo(App));
