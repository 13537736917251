import {
    SET_THEME,
    GET_THEME
} from './actionTypes'
import { themeConfig } from '@/config'
import { initTheme } from '@/utils'
import { storage } from '@/utils/storage'
import { ConfigProvider } from 'antd';
function getImagesArr (defaults) {
    let imagesNameArr = [];
    let requireModule = require.context(
        "@/assets/images/theme-imgs",
        false,
        /\.png$/
    );
    for (let i = 0; i < requireModule.keys().length; i++) {
        imagesNameArr.push(requireModule.keys()[i].substr(2, requireModule.keys()[i].length));
    }
    defaults = imagesNameArr.map((el, i) => {
        return {
            id: i + 1,
            url: require("@/assets/images/theme-imgs/" + imagesNameArr[i]).default,
            theme: defaults[i] || defaults[0]
        };
    })
    return defaults
}
let defaults = getImagesArr(themeConfig.defaults)
const defaultState = {
    themeConfig: {
        ...themeConfig,
        ...storage.get('themeConfig'),
        defaults
    },
    displayColorPicker: false,
}

const themeState = (state = defaultState, action) => {
    if (action.type === SET_THEME) {
        let theme = { ...themeConfig, defaults, ...action.value }
        storage.set('themeConfig', theme)
        initTheme(theme).then(config => {
            ConfigProvider.config({
                prefixCls: 'ant',
                theme: {
                    primaryColor: config.theme.primaryColor,
                }
            });
        })
        return Object.assign({}, state, {
            themeConfig: {
                ...theme
            }
        })
    }
    if (action.type === GET_THEME) {
        let activeTheme = { ...themeConfig, defaults, ...storage.get('themeConfig') }
        const newState = {
            ...state,
            themeConfig: {
                ...activeTheme
            }
        }
        return newState
    }
    return state
}
export {
    themeState
}