
import baseUrl from '@/api/baseUrl'
// const baseUrl = '/lrk'
// const yapiUrl = 'http://yapi.yunzhonghe.com/mock/289/user'
const prefix = '/role'
const urls = {
    listAll: `${baseUrl}${prefix}/list`,
    list: `${baseUrl}${prefix}/pageList`,
    add: `${baseUrl}${prefix}/add`,
    edit: `${baseUrl}${prefix}/edit`,
    remove: `${baseUrl}${prefix}/remove`,
    menuTree: `${baseUrl}/menu/tree`,
    setAuthority: `${baseUrl}${prefix}/user/setAuthority`,
    selectMenuIds: `${baseUrl}/menu/selectMenuIds`,
    copyMenu: `${baseUrl}/menu/copyMenu`,
    // authDetail: `${yapiUrl}${prefix}/auth/detail`, // 数据权限详情查看
    // comboBox: `${yapiUrl}${prefix}/auth/comboBox`, // 数据权限下拉框
    authDetail: `${baseUrl}${prefix}/auth/sensitiveField`, // 数据权限详情查看
    comboBox: `${baseUrl}${prefix}/auth/comboBox` // 数据权限下拉框
}
export default urls
