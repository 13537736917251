// 设置主题
export const SET_THEME = 'setTheme'
export const GET_THEME = 'getTheme'

// 用户信息
export const SET_USERINFO = 'setUserInfo'


// 设置页签路由
export const ADD_TABS = 'addTabs'
export const REMOVE_TABS = 'removeTabs'
export const CHANGE_TABS = 'changeTabs'
export const FILTER_TABS = 'filterTabs'