import userCenter from './modules/userCenter'
import logCenter from './modules/logCenter'
import salesmanSetting from './modules/salesmanSetting'
// import order from './modules/order'
// import goods from './modules/goods'
import apps from './modules/apps'
import { permissionCodes as codes } from './permission'

import { asyncLoadComponent } from '@/routes/asyncLoadComponent'
import {
    HomeOutlined,
    UserOutlined,
    SettingOutlined,
    // ReconciliationOutlined,
    AppstoreAddOutlined
} from '@ant-design/icons';
let routes = [
    {
        affix: true, // 如果设置为true，它则会固定在tags-view中(默认 false)
        path: '/',
        exact: true,
        auth: true,
        index: 0,
        // pIndex: null,
        meta: {
            title: '首页',
            roles: [codes.user_center],
            icon: <HomeOutlined />
        },
        component: asyncLoadComponent(() => import('@/pages/home')),
    },
    {
        path: '/userManage',
        exact: false,
        auth: true,
        index: 0,
        // pIndex: null,
        meta: {
            title: '系统管理',
            roles: [codes.user_center],
            icon: <SettingOutlined />
        },
        component: asyncLoadComponent(() => import('@/pages')),
        children: [
            userCenter,
            salesmanSetting,
            {
                path: '/userManage/appManage',
                exact: true,
                auth: true,
                index: 3,
                // pIndex: null,
                component: asyncLoadComponent(() => import('@/pages/appManage/index')),
                meta: {
                    title: '应用管理',
                    roles: [codes.application.list],
                    icon: <AppstoreAddOutlined />
                },
            },
            {
                path: '/userManage/chat',
                exact: true,
                auth: true,
                index: 3,
                // pIndex: null,
                component: asyncLoadComponent(() => import('@/pages/logCenter/chat/configPage')),
                meta: {
                    title: '数据统计',
                    roles: ['user_data_stats_chart'],
                    icon: <AppstoreAddOutlined />
                },
            },
            logCenter,
        ],
    },
    // ...apps,
    // order,
    // goods,
    // userCenter,
    // baseSetting,
    // {
    //     path: '/appManage',
    //     exact: true,
    //     auth: true,
    //     index: 3,
    //     // pIndex: null,
    //     component: asyncLoadComponent(() => import('@/pages/appManage/index')),
    //     meta: {
    //         title: '应用管理',
    //         roles: [codes.application.list],
    //         icon: 'AppstoreAddOutlined'
    //     },
    // },
    // logCenter,
    {
        path: '/login',
        exact: true,
        auth: false,
        index: 4,
        // pIndex: null,
        meta: {
            hideLayout: true,
            title: '登录',
            hideInMenu: true,
            icon: 'AppstoreOutlined'
        },
        component: asyncLoadComponent(() => import('@/pages/login')),
    },
    {
        path: '/browser',
        exact: true,
        auth: false,
        index: 4,
        // pIndex: null,
        meta: {
            hideLayout: true,
            title: '下载浏览器',
            hideInMenu: true,
            icon: 'AppstoreOutlined'
        },
        component: asyncLoadComponent(() => import('@/pages/browser/index')),
    },
    {
        path: '/404',
        exact: true,
        auth: false,
        index: 5,
        // pIndex: null,
        meta: {
            hideLayout: true,
            title: '页面不存在',
            hideInMenu: true,
            icon: 'AppstoreOutlined'
        },
        component: asyncLoadComponent(() => import('@/pages/404')),
    },
]
export default routes