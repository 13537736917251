// 有些接口需要在header中添加token或是其他，可以按如下配置
import axios from '@/api/axios'
import urls from './urls'
import { permissionCodes as codes } from '@/routes/permission'
const header = {
    'Content-Type': 'multipart/form-data'
}
export default {
    header,
    customerSplittingList (params, code) {
        return axios.post(urls.customerSplittingList, params, {
            headers: {
                OperationCode: code || codes.admin.customer.list
            }
        })
    },
    managerList (data, code) {
        return axios.get(urls.managerList, {
            params: data,
            headers: {
                OperationCode: code || codes.admin.customer.list
            }
        })
    },
    isHasManager (params, code) {
        return axios.get(urls.isHasManager, {
            params,
            headers: {
                OperationCode: code || codes.admin.customer.list
            }
        })
    },
    detachManager (params, code) {
        return axios.post(urls.detachManager, params, {
            headers: {
                OperationCode: code || codes.admin.customer.update
            }
        })
    },
    deleteCustomerAdmin (params, code) {
        return axios.post(urls.deleteCustomerAdmin, params, {
            headers: {
                OperationCode: code || codes.admin.customer.delete
            }
        })
    },
    addManager (params, code) {
        return axios.post(urls.addManager, params, {
            headers: {
                OperationCode: code || codes.admin.customer.update
            }
        })
    },
    // 导出客户分单
    exportCustomerAdmin (params, code) {
        return axios.post(urls.exportCustomerAdmin, params, {
            headers: {
                OperationCode: code || codes.admin.customer.export
            }
        })
    },
    exchangeCustomerAdmin (params, code) {
        return axios.post(urls.exchangeCustomerAdmin, params, {
            headers: {
                OperationCode: code || codes.admin.customer.exchange
            }
        })
    },
    deleteMoreCustomerAdmin (params, code) {
        return axios.post(urls.deleteMoreCustomerAdmin, params, {
            headers: {
                OperationCode: code || codes.admin.customer.delete
            }
        })
    },
    exportCustomerAdminErr (data, code) {
        return axios.get(urls.exportCustomerAdminErr, {
            params: data,
            headers: {
                OperationCode: code || codes.admin.customer.list
            }
        })
    },
}
