
// import baseUrl from '@/api/baseUrl'
// import appUrl from '@/api/baseUrl'
import { appUrl, baseUrl } from '@/api/baseUrl'
// const baseUrl = '/lrk'
const prefix = '/dept'
const urls = {
    list: `${baseUrl}${prefix}/list`,
    tree: `${baseUrl}${prefix}/tree/authskip`,
    add: `${baseUrl}${prefix}/add`,
    edit: `${baseUrl}${prefix}/edit`,
    remove: `${baseUrl}${prefix}/remove`,
    sort: `${baseUrl}${prefix}/deptSort`,
    info: `${baseUrl}${prefix}/queryDeptBaseInfo`,

    queryCategoryTree: `${appUrl}/goods/category/queryCategoryTree/authskip`
    // https://gateway-show.yunzhonghe.com/api/goods/category/queryCategoryTree/authskip
}
export default urls
