import axios from '@/api/axios'
import urls from './urls'
import { permissionCodes as codes } from '@/routes/permission'
const role = {
    list (params) {
        params.roleType = 0
        return axios.get(urls.list, {
            params, headers: {
                OperationCode: codes.role.list
            }
        })
    },
    listAll (params = {}, code) {
        params.roleType = 0
        return axios.get(urls.listAll, {
            params, headers: {
                OperationCode: code || codes.user.list
            }
        })
    },
    add (params) {
        params.roleType = 0
        return axios.post(urls.add, params, {
            headers: {
                OperationCode: codes.role.add
            }
        })
    },
    edit (params) {
        params.roleType = 0
        return axios.post(urls.edit, params, {
            headers: {
                OperationCode: codes.role.edit
            }
        })
    },
    remove (params) {
        return axios.post(urls.remove, params, {
            headers: {
                OperationCode: codes.role.remove
            }
        })
    },
    setAuthority (params) {
        params.roleType = 0
        return axios.post(urls.setAuthority, params, {
            headers: {
                OperationCode: codes.role.authority
            }
        })
    },
    menuTree (params) {
        params.roleType = 0
        return axios.get(urls.menuTree, {
            params, headers: {
                OperationCode: codes.role.authority
            }
        })
    },
    selectMenuIds (params) {
        return axios.get(urls.selectMenuIds, {
            params, headers: {
                OperationCode: codes.role.authority
            }
        })
    },
    copyMenu (params) {
        return axios.get(urls.copyMenu, {
            params, headers: {
                OperationCode: codes.role.authority
            }
        })
    },
    authDetail (params) {
        return axios.get(urls.authDetail, {
            params, headers: {
                OperationCode: codes.role.authority
            }
        })
    },
    comboBox (params) {
        return axios.get(urls.comboBox, {
            params, headers: {
                OperationCode: codes.role.authority
            }
        })
    }
}
export default role
