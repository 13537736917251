// 把一个类型下的所有url接口放入这一个文件
import { appUrl, operationCenterUrl } from '@/api/baseUrl'
// const goodsprefix = '/order'
// let appUrl = '/yd'
const prefix = '/goods'
export default {
    deleteServiceProvider: appUrl + prefix + '/serviceProvider/deleteServiceProvider',
    importServiceProvider: appUrl + prefix + '/serviceProvider/importServiceProvider',
    exportServiceProvider: appUrl + prefix + '/serviceProvider/exportServiceProvider',
    saveOrUpdateServiceProvider: appUrl + prefix + '/serviceProvider/saveOrUpdateServiceProvider',
    serviceProviderSearchPage: appUrl + prefix + '/serviceProvider/searchPage',
    searchSettlementType: appUrl + prefix + '/serviceProvider/searchSettlementType',
    updateSettlementType: appUrl + prefix + '/serviceProvider/updateSettlementType',
    findsupplier: appUrl + prefix + '/serviceProvider/findsupplier',
}
