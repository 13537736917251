/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
import {
    Input,
    Select,
    Form, message,
    Modal
} from 'antd';
import api from '@/api/apiList'
import { ValidationRules } from '@/utils'
import { storage } from '@/utils/storage'
import { connect } from 'react-redux'
import {
    ADD_TABS,
    REMOVE_TABS,
    CHANGE_TABS,
    FILTER_TABS,
} from '@/store/actionTypes'
import config from '@/config'
const { setting } = config
const { confirm } = Modal;
const { Option } = Select;
const YzhPwdDialog = (props) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        ...props
    });
    useEffect(() => {
        setState({
            ...state,
            visable: props.visable
        })
    }, [props.visable])
    // 重置密码
    const onResetPwd = async (values) => {
        form
            .validateFields()
            .then(async (values) => {
                try {
                    let apiStr = 'changePwd'
                    let params = {
                        oldPassword: values.oldPassword,
                        newPassword: values.password,
                    }
                    if (!props.changePwd) {
                        if (values.password !== values.confirmPassword) {
                            message.error('输入的两次密码不一致，请重新输入！')
                            return
                        }
                        apiStr = 'resetPwd'
                        params = {
                            id: props.userId,
                            oldPassword: values.oldPassword,
                            pwd: values.password
                        }
                    }
                    let res = await api.userCenter.user[apiStr](params)
                    if (res && res.success) {
                        message.success(res.message)
                        if (storage.get('userInfo') &&
                            storage.get('userInfo').userId === props.userId) {
                            message.success(props.globalConfig.setting.message.changePwd)
                            props.logout()
                            props.filterTabs('3')
                            return
                        }
                        form.resetFields()
                        props.onClose()
                    }
                } catch (error) {
                    console.log(error)
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    const { visable } = state
    return (
        <Modal
            maskClosable={false}
            centered
            title={`${props.changePwd ? '修改' : '重置'}密码`}
            width={460}
            wrapClassName="yzh-modal"
            visible={visable}
            onOk={onResetPwd}
            onCancel={() => {
                setState({
                    ...state,
                    visable: false,
                })
                form.resetFields()
                props.onClose()
            }}
        >
            <Form
                name="resetPwdForm"
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValues={{}}
                autoComplete="off"
            >
                {props.changePwd && <Form.Item
                    label="原始密码"
                    name="oldPassword"
                    rules={[{
                        required: true,
                        // validator: (rule, value) => {
                        //     return new ValidationRules({
                        //         prop: 'oldPassword',
                        //         message: '密码',
                        //         type: 'password',
                        //         limit: 12,
                        //         inputControll: true,
                        //         value
                        //     }).validate(rule, value)
                        // },
                    }]}
                >
                    <Input.Password
                        placeholder="请输入原始密码" />
                </Form.Item>}
                <Form.Item
                    label=" 新密码"
                    name="password"
                    rules={[{
                        required: true,
                        validator: (rule, value) => {
                            return new ValidationRules({
                                prop: 'password',
                                message: '密码',
                                type: 'password',
                                limit: 12,
                                inputControll: true,
                                value
                            }).validate(rule, value)
                        },
                    }]}
                >
                    <Input.Password
                        placeholder={setting.message.password} />
                </Form.Item>
                <Form.Item
                    label="确认密码"
                    style={{ marginBottom: 0 }}
                    name="confirmPassword"
                    rules={[{
                        required: true,
                        validator: (rule, value) => {
                            return new ValidationRules({
                                prop: 'password',
                                message: '密码',
                                type: 'password',
                                limit: 12,
                                inputControll: true,
                                value
                            }).validate(rule, value)
                        },
                    }]}
                >
                    <Input.Password
                        placeholder={setting.message.password} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
//需要触发什么行为
function mapDispatchToProps (dispatch, props) {
    return {
        filterTabs: (value) => {
            dispatch({
                type: FILTER_TABS,
                value,
                ...props
            })
        },
        logout: (value) => {
            dispatch({
                type: 'logout',
                value,
                ...props
            })
        },
    }
}
export default connect(() => ({}), mapDispatchToProps)(YzhPwdDialog);