// 把一个类型下的所有url接口放入这一个文件
import { appUrl, operationCenterUrl } from '@/api/baseUrl'
// const goodsprefix = '/order'
// let appUrl = '/yd'
// let operationCenterUrl = '/operationApi/operation'
let goodsUrl = appUrl + '/goodsApi/goods'
let orderUrl = appUrl + '/orderApi/order'
export default {
    carrierList: orderUrl + '/shipmentCompany/shipmentList/authskip',

    customerCarrierList: operationCenterUrl + '/customer/shipment/page',
    isHasCarrier: operationCenterUrl + '/customer/shipment/hasShipment',
    detachCarrier: operationCenterUrl + '/customer/shipment/bindShipmentList',
    exportCarrier: operationCenterUrl + '/customer/shipment/exportCustomerAdmin',
    changeCarrier: operationCenterUrl + '/customer/shipment/updateShipment',
    settlemntCustomerList: operationCenterUrl + '/invoice/customerQueryOrder',
    customerAllList: operationCenterUrl + '/invoice/authskip',
    exportSettleMentCustomers: operationCenterUrl + '/invoice/exportCustomerAdmin',
    deleteCustomerInvoice: operationCenterUrl + '/invoice/deleteCustomerInvoice',
    websiteComboBox: operationCenterUrl + '/website/comboBox',
    customer: operationCenterUrl + '/customer/comboBox/authskip',
    importCustomerInvoice: appUrl + '/customer/invoice/importCustomerInvoice',

    getGoodsNameByErpCode: goodsUrl + '/info/getGoodsInfoByErpCode',
    supplier: goodsUrl + '/search/all/supplier',
}
