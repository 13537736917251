import axios from '@/api/axios'
import urls from './urls'
import { permissionCodes as codes } from '@/routes/permission'
// import baseUrl from '@/api/baseUrl'
// const baseUrl = 'http://yapi.yunzhonghe.com/mock/258'
const app = {
    list (params) {
        return axios.get(urls.list, {
            params, headers: {
                OperationCode: codes.application.list
            }
        })
    },
    getYtHpByOrder (params) {
        return axios.get(urls.getYtHpByOrder, {
            params, headers: {
                OperationCode: 'yt_home_page_statistics'
            }
        })
    },
    queryCount (params) {
        return axios.get(urls.queryCount, {
            params
        })
    },
    home (params) {
        return axios.get(urls.home, {
            params, headers: {
                OperationCode: codes.user_center
            }
        })
    },
    add (params) {
        return axios.post(urls.add, params, {
            headers: {
                OperationCode: codes.application.add
            }
        })
    },
    edit (params) {
        return axios.post(urls.edit, params, {
            headers: {
                OperationCode: codes.application.edit
            }
        })
    },
    remove (params) {
        return axios.post(urls.remove, params, {
            headers: {
                OperationCode: codes.application.remove
            }
        })
    },
    info (params) {
        return axios.get(urls.info, {
            params, headers: {
                OperationCode: codes.application.edit
            }
        })
    },
    sort (params) {
        return axios.post(urls.sort, params, {
            headers: {
                OperationCode: codes.application.sort
            }
        })
    },
}
export default app
