import React from 'react';
import config from '@/config'
import { Select, Button } from 'antd';
import axios from '@/api/axios';
import { appUrl } from '@/api/baseUrl'
import PropTypes from 'prop-types';
import './index.scss';
const { fieldProps } = config.antConfig.tableConfig.columnConfig;

class YzhCitySelect extends React.Component {
    static defaultProps = { ...fieldProps, notFoundContent: '暂无数据', style: {marginLeft: '-1px'} };
    constructor(props) {
        super(props);
        this.state = {
            province: [],
            city: [],
            county: [],
            town: [],
            provinceId: props.region?.provinceId || undefined,
            cityId: props.region?.cityId || undefined,
            countyId: props.region?.countyId || undefined,
            townId: props.region?.townId || undefined,
            provinceName: props.region?.provinceName || undefined,
            cityName: props.region?.cityName || undefined,
            countyName: props.region?.countyName || undefined,
            townName: props.region?.townName || undefined
        }
    }
    //如果条件不存在必须要返回null
    // static getDerivedStateFromProps (props, current_state) {
    //     console.log(props.params, current_state.params, 'props, current_state');
    //     if (JSON.stringify(props.params) !== JSON.stringify(current_state.params)) {
    //         return {
    //             params: props.params
    //         }
    //     }
    //     return null
    // }
    handleChange = (source, value, key) => {
        // const { disabled } = this.props.props || {}
        // this.props.onChange && this.props.onChange(value, selectedRow || this.state.data)
        this.getCityData(source, value, key)
    }
    componentDidMount () {
        this.getCityData([], 0, 'province').then(province => {
            const {region = {}} = this.props
            if (region.provinceId && province.length) {
                this.getCityData(province, region.provinceId, 'city', true).then(city => {
                    if (region.cityId && city.length) {
                        this.getCityData(city, region.cityId, 'county', true).then(county => {
                            if (region.countyId && county.length) {
                                this.getCityData(county, region.countyId, 'town', true).then(town => {
                                    if (region.townId && town.length) {
                                        this.getCityData(town, region.townId, '', true)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }
    getCityData (source = [], parentId, key, isInit) {
        return new Promise((resolve, reject) => {
            let url = `${appUrl}/goods/regionalInfo/getAllProvince`
            if (key !== 'province') {
                url = `${appUrl}/goods/regionalInfo/getByParentId`
            }
            if (!key || parentId === '' || parentId === undefined) {
                !key && parentId && this.setState({
                    ...this.state,
                    townName: source.filter(el => el.value === parentId)[0]?.label,
                    townId: parentId
                })
                return
            }
            let prop =  !key ? 'townId' : key === 'city' ? 'provinceId' : key === 'county' ? 'cityId' : 'countyId'
            if (this.props.level === 3 && key === 'town') {
                this.setState({
                    ...this.state,
                    countyName: source.filter(el => el.value === parentId)[0]?.label,
                    [prop]: parentId
                })
                this.props.onChange && this.props.onChange({
                    ...this.state,
                    countyName: source.filter(el => el.value === parentId)[0]?.label,
                    [prop]: parentId
                })
                return
            }
            this.setState({
                ...this.state,
                [prop]: parentId
            })
            this.props.onChange && this.props.onChange({
                ...this.state,
                [prop]: parentId
            })
            axios.get(url, {
                params: { parentId },
                headers: { OperationCode: 'supplier_area_add' }
            }).then(res => {
                if (res.success && res.result !== null) {
                    let state = {
                        ...this.state,
                        [key]: res.result.map(el => ({label: el.regionName, value: el.id}))
                    }
                    if (key === 'city') {
                        state = {
                            ...state,
                            provinceName: source.filter(el => el.value === parentId)[0]?.label,
                            cityId: isInit ? state.cityId : res.result[0].id,
                            cityName: isInit ? state.cityName : res.result[0].regionName,
                            county: [],
                            town: [],
                            countyId: isInit ? state.countyId : undefined,
                            countyName: isInit ? state.countyName : undefined,
                            townId: isInit ? state.townId : undefined,
                            townName: isInit ? state.townName : undefined,
                        }
                        !isInit && this.getCityData(state[key], res.result[0].id, 'county')
                    } else if (key === 'county') {
                        state = {
                            ...state,
                            cityName: source.filter(el => el.value === parentId)[0]?.label,
                            countyId: isInit ? state.countyId : res.result[0].id,
                            countyName: isInit ? state.countyName : res.result[0].regionName,
                            town: [],
                            townId: isInit ? state.townId : undefined,
                            townName: isInit ? state.townName : undefined,
                        }
                        !isInit && this.getCityData(state[key], res.result[0].id, 'town')
                    } else if (key === 'town') {
                        state = {
                            ...state,
                            countyName: source.filter(el => el.value === parentId)[0]?.label,
                            townId: isInit ? state.townId : res.result[0].id,
                            townName: isInit ? state.townName : res.result[0].regionName
                        }
                        !isInit && this.getCityData(state[key], res.result[0].id, '')
                    }
                    this.setState(state)
                    this.props.onChange && this.props.onChange(state)
                    resolve(res.result.map(el => ({label: el.regionName, value: el.id})))
                } else if (res.result === null) {
                    this.setState({
                        ...this.state,
                        countyName: source.filter(el => el.value === parentId)[0]?.label,
                    })
                    this.props.onChange && this.props.onChange({
                        ...this.state,
                        countyName: source.filter(el => el.value === parentId)[0]?.label,
                    })
                    resolve([])
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }
    onClear (key) {
        let state = {
            ...this.state,
        }
        if (key === 'province') {
            state = {
                ...state,
                city: [],
                county: [],
                town: [],
                provinceId: undefined,
                provinceName: undefined,
                cityId: undefined,
                cityName: undefined,
                countyId: undefined,
                countyName: undefined,
                townId: undefined,
                townName: undefined
            }
        } else if (key === 'city') {
            state = {
                ...state,
                county: [],
                town: [],
                cityId: undefined,
                cityName: undefined,
                countyId: undefined,
                countyName: undefined,
                townId: undefined,
                townName: undefined
            }
        } else if (key === 'county') {
            state = {
                ...state,
                town: [],
                countyId: undefined,
                countyName: undefined,
                townId: undefined,
                townName: undefined,
            }
        } else if (key === 'town') {
            state = {
                ...state,
                townId: undefined,
                townName: undefined
            }
        }
        this.setState(state)
        this.props.onChange && this.props.onChange(state)
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }
    // // // 因为两次生成的随机数可能相同，如果相同，此时，不需要重新渲染
    // shouldComponentUpdate (nextProps, nextState) {
    //     console.log('最新状态：', nextProps, ', 当前状态：', this.state)
    //     return JSON.stringify(nextProps.params) !== JSON.stringify(this.state.params) || JSON.stringify(nextProps.value) !== JSON.stringify(this.state.value)
    // }
    render () {
        const { onAction, ...props } = this.props
        return <div className='city-select'>
            <Select
                showSearch
                {...this.defaultProps}
                {...props}
                mode=""
                value={this.state.provinceId}
                onClear={() => this.onClear('province')}
                onChange={(val, row) => this.handleChange(this.state.province, val, 'city')}
                // onSelect={(val) => this.handleChange(val, 'city', 'select')}
                virtual={true}
                options={this.state.province}
            />
            {this.state.city.length ? <Select
                showSearch
                {...this.defaultProps}
                {...props}
                mode=""
                value={this.state.cityId}
                onClear={() => this.onClear('city')}
                onChange={(val, row) => this.handleChange(this.state.city, val, 'county')}
                virtual={true}
                options={this.state.city}
            /> : null}
            {this.state.county.length ? <Select
                showSearch
                {...this.defaultProps}
                {...props}
                mode=""
                value={this.state.countyId}
                onClear={() => this.onClear('county')}
                onChange={(val, row) => this.handleChange(this.state.county, val, 'town')}
                virtual={true}
                options={this.state.county}
            /> : null}
            {this.state.town.length && this.props.level !== 3 ? <Select
                showSearch
                {...this.defaultProps}
                {...props}
                mode=""
                value={this.state.townId}
                onClear={() => this.onClear('town')}
                onChange={(val, row) => this.handleChange(this.state.town, val, '')}
                virtual={true}
                options={this.state.town}
            /> : null}
            {this.props.onAction ?
                <Button
                type="text"
                onClick={() => this.props.onAction({...this.state, type: 'add'})}
                style={{ marginLeft: 10, padding: 0 }}
                >添加</Button> : null
            }
        </div>
    }
}
YzhCitySelect.propTypes = {
    request: PropTypes.oneOfType([PropTypes.string]), // 请求地址
    params: PropTypes.oneOfType([PropTypes.object]), // 请求参数
    props: PropTypes.oneOfType([PropTypes.object]), // 自定义 value, label 字段
    key: PropTypes.oneOfType([PropTypes.string]), // 查询字段
    // value: propTypes.any, // 选中的值
}
export default YzhCitySelect
