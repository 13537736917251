// 把一个类型下的所有url接口放入这一个文件
import { appUrl, operationCenterUrl } from '@/api/baseUrl'
// const goodsprefix = '/order'
// let appUrl = '/goodsApi'
const prefix = '/goods/supplierAdmin'
export default {
    supplierCustomerSplittingList: appUrl + prefix + '/supplierQueryOrder',
    supplierIsHasManager: appUrl + prefix + '/SupplierHasManager',
    supplierDetachManager: appUrl + prefix + '/addMorderSupplierAdmin',
    supplierAddManager: appUrl + prefix + '/addSupplierAdmin',
    supplierManagerList: appUrl + prefix + '/selectAllAdmin',
    supplierDeleteCustomerAdmin: appUrl + prefix + '/deleteSupplierAdmin',
    deleteMoreSupplierAdmin: appUrl + prefix + '/deleteMoreSupplierAdmin',
    exchangeSupplierAdmin: appUrl + prefix + '/exchangeSupplierAdmin',
    supplierExportCustomerAdmin: appUrl + prefix + '/exportSupplierAdmin',
    exportSupplierAdminErr: appUrl + prefix + '/exportSupplierAdminErr',
}
