import configPage from "./configManage";
import axios from '@/api/axios'
import urls from './urls'
import { permissionCodes as codes } from '@/routes/permission'
const logApi = {
    list (params, code) {
        return axios.get(urls.list, {
            params, headers: {
                OperationCode: code || codes.logCenter.list
            }
        })
    },
    configPage
}
export default logApi
