import React from 'react';
import { Route } from 'react-router-dom' // 引入路由组件
// 路由高阶组件
import {
    MenuUnfoldOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    MenuFoldOutlined,
    DownOutlined,
    BgColorsOutlined,
    ReloadOutlined,
    UserOutlined
} from '@ant-design/icons';
import {
    Layout, Menu,
    Dropdown,
    Drawer,
    Avatar,
    Modal, Input, Row, Col,
} from 'antd';
import YzhTheme from '@/components/YzhTheme';
import TabsBar from '@/layout/tabs'
import {
    changeFullScreen,
    isFullScreen,
} from '@/utils'
import {
    storage
} from '@/utils/storage'
import YzhPwdDialog from '@pages/userCenter/userManage/resetPwd'
import ExtraOperation from './extraOperation'
const { Header } = Layout;
const { TextArea } = Input;
class UserDropdown extends React.Component {
    state = {
        visible: false,
        userInfo: {}
    };

    handleMenuClick = async e => {
        if (e.key === '1') {
            if (this.props.activeKey === '/userManage/userCenter/userInfo') return
            this.props.addTabs({ activeKey: '/userManage/userCenter/userInfo', title: '个人中心' })
            this.props.history.push('/userManage/userCenter/userInfo')
            return
        }
        if (e.key === '2') {
            // this.props.addTabs({ activeKey: '/userManage/userCenter/edit', title: '编辑个人信息' })
            // this.props.history.push('/userManage/userCenter/edit')
            this.props.setPasswordVisiable(true)
        }
        const { modalConfig } = this.props.globalConfig.antConfig
        if (e.key === '3') {
            Modal.confirm({
                ...modalConfig,
                content: '确定退出登录？',
                okText: '确认',
                onOk: async (action) => {
                    try {
                        this.props.logout()
                        this.props.filterTabs('3')
                        // storage.remove('themeConfig')
                        // initTheme(config.themeConfig).then((config) => {
                        //     this.props.setTheme({
                        //         ...this.props.themeConfig,
                        //         id: 1,
                        //         theme: {
                        //             ...config.theme,
                        //             primaryColor: config.theme.primaryColor
                        //         }
                        //     })
                        //     ConfigProvider.config({
                        //         prefixCls: 'ant',
                        //         theme: {
                        //             primaryColor: config.theme.primaryColor,
                        //         }
                        //     });
                        // })
                        return
                    } catch (error) {
                        console.log(error);
                    }
                },
                onCancel: () => {
                    // actionRef.current.reload()
                }
            })
        }
        this.setState({
            visible: false
        })
    };
    componentDidMount () {
        // window.addEventListener('storage', function (e) {
        //     console.log(e, 'storage');
        // })
    }
    handleVisibleChange = flag => {
        this.setState({ visible: flag });
    };

    render () {
        const { userInfo = {} } = this.props
        const items = [{
            label: '个人中心',
            title: '个人中心',
            key: '1'
        }, {
            label: '修改密码',
            title: '修改密码',
            key: '2'
        }, {
            label: '退出登录',
            title: '退出登录',
            key: '3'
        }]
        const menu = (
            <Menu onClick={this.handleMenuClick} items={items} />
        );
        return (
            <Dropdown
                overlay={menu}
                onVisibleChange={this.handleVisibleChange}
                visible={this.state.visible}
            >
                <div className="dropdown-link" onClick={e => {
                    e.preventDefault();
                    this.handleMenuClick({ key: '1' })
                }}>
                    <Avatar icon={<UserOutlined />} src={userInfo.avatar} />
                    <span className="username"> {userInfo.name || 'admin'} </span><DownOutlined />
                </div>
            </Dropdown>
        );
    }
}
class YzhHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: this.props.collapsed,
            isFullscreen: false,
            themeConfig: {},
            showThemePanel: false,
            hideTheme: false,
            isRefresh: false,
            passwordVisiable: false,
            userInfo: {}
        }
    }
    //如果条件不存在必须要返回null
    static getDerivedStateFromProps (props, current_state) {
        if (props.collapsed !== current_state.collapsed) {
            return {
                collapsed: props.collapsed
            }
        }
        return null
    }
    // 展开收起菜单事件处理函数
    toggleMenu = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
        this.props.toggleMenu(!this.state.collapsed)
        // console.log(this.state.collapsed)
    };
    reload = () => {
        this.setState({
            isRefresh: !this.state.isRefresh
        });
        this.props.reload(!this.state.isRefresh)
    };
    // 全屏事件处理函数
    toggleFullScreen = (e) => {
        const ele = document.getElementById('root'); // 全屏元素
        changeFullScreen(ele)
        this.setState({
            isFullscreen: !this.state.isFullscreen
        });
    }
    setPasswordVisiable = (passwordVisiable) => {
        this.setState({
            passwordVisiable: passwordVisiable
        });
    }
    // 主题弹框事件处理函数
    toggleShowThemePanel = () => {
        this.setState({
            hideTheme: false,
            showThemePanel: !this.state.showThemePanel,
        });
        if (this.state.showThemePanel) {
            this.timer = setTimeout(() => {
                this.setState({
                    hideTheme: true,
                });
            }, 100);
        }
    };
    componentDidMount () {
        window.addEventListener('resize', () => {
            !isFullScreen() && this.setState({
                isFullscreen: false
            })
        })
        try {
            let userInfo = storage.get('userInfo')
            if (userInfo && userInfo !== null) {
                this.setState({
                    themeConfig: this.props.themeConfig,
                    userInfo
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    componentWillUnmount () {
        clearTimeout(this.timer)
        this.setState = () => false;
    }
    render () {
        const {
            userInfo,
            collapsed,
            showThemePanel,
            hideTheme,
            passwordVisiable } = this.state
        const { showTabsBar, tabsLayout, showRefresh } = this.props.themeConfig
        return (
            <Header className="yzh-header" style={{ padding: 0 }}>
                <Row style={{height: '100%'}}>
                    <Col style={{height: '100%'}} span={12} lg={{span: 3}} className="clearfix row align-c">
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: this.toggleMenu,
                        })}
                        {showRefresh && React.createElement(ReloadOutlined, {
                            className: 'trigger',
                            style: {
                                padding: '0 0 0 35px'
                            },
                            title: '刷新',
                            onClick: this.reload,
                        })}
                    </Col>
                    {showTabsBar && tabsLayout === 'vertical' ? <Route render={props => {
                        return <Col style={{height: '100%'}} span={0} xl={{span: 5}}><TabsBar {...this.props} {...props} layout={tabsLayout} className="" /></Col>
                    }}>
                    </Route> : null}
                    <Col span={0} lg={{span: 15}} style={{height: '100%'}} xl={{span: showTabsBar && tabsLayout === 'vertical' ? 12 : 17}}>
                        <ExtraOperation  {...this.props} />
                    </Col>
                    <Col span={12} style={{height: '100%'}} lg={{span: 6}} xl={{span: 4}}>
                        <div className="user-info row align-c" style={{flexWrap: 'no-wrap'}}>
                            <div onClick={this.toggleFullScreen} className="full-screen">
                                {
                                    this.state.isFullscreen ?
                                        <FullscreenExitOutlined /> :
                                        <FullscreenOutlined />
                                }
                            </div>
                            <UserDropdown {...this.props} setPasswordVisiable={this.setPasswordVisiable}></UserDropdown>
                            {<YzhPwdDialog visable={passwordVisiable} changePwd {...this.props} userId={userInfo.userId} onClose={() => {
                                this.setState({
                                    passwordVisiable: false
                                })
                            }} />}
                            <BgColorsOutlined onClick={this.toggleShowThemePanel} />
                            <Drawer
                                title="设置主题"
                                width="360"
                                placement="right"
                                onClose={this.toggleShowThemePanel} visible={showThemePanel}>
                                {!hideTheme && <YzhTheme {...this.props} />}
                            </Drawer>
                        </div>
                    </Col>
                </Row>
            </Header>
        );
    }
}
export default YzhHeader;
