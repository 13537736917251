import { useCallback, useEffect, useRef, useState } from 'react';

export function useCustomState (...props) {
    const focus = useRef();
    const [state, setState] = useState(...props);
    useEffect(() => {
        focus.current = true;
        return () => (focus.current = false);
    }, []);
    const setCustomState = useCallback((...params) => {
        focus.current && setState(...params);
    }, []);
    return [state, setCustomState];
}