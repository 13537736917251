import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tree, Skeleton, TreeSelect, Cascader } from 'antd';
import { CarryOutOutlined } from '@ant-design/icons';
import api from '@/api/apiList';
import { arrayToTree, setTreeProps, findParentNodes } from '@/utils'
import { useCustomState } from '@/hooks/useEffect';
import { YzhEmpty } from '@com'
const { TreeNode } = TreeSelect;
const TreeComponent = (props) => {
    const [loading, setLoading] = useCustomState(true)
    let [state, setState] = useCustomState({
        treeData: props.options || [],
        value: props.value || undefined,
        pId: props.pId || undefined,
        defaultExpandedKeys: ['0'],
        refresh: false,
        arrayToTree: arrayToTree
    });
    const onSelect = (selectedKeys, info) => {
        console.log(info, selectedKeys, 'ParentNodes');
        props.onSelect(props.select ? selectedKeys : selectedKeys[0], props.select ? info : info.node)
    };
    useEffect(() => {
        let didUnmounted = false;
        if (didUnmounted) return
        const getTreeData = async () => {
            let res = await api.userCenter.department.tree({}, props.pcode)
            let treeData = res && res.result !== null ? res.result : []
            treeData = arrayToTree(treeData, { children: 'nodes', pId: 'pid', id: 'id' })
            let newExpandedKeys = []
            newExpandedKeys = setTreeProps(treeData).newExpandedKeys
            treeData = setTreeProps(treeData,
                (item) => {
                    // console.log([...findParentNodes(treeData, item.pid), item].map(el => el.name).join('/'), item.pid, 'item');
                    return ({
                        icon: <CarryOutOutlined />,
                        label: item.pid ? [...findParentNodes(treeData, item.pid), item].map(el => el.name).join('/') : item.name
                    })
                }).tree
            setState({
                value: props.value || undefined,
                pId: props.pId || undefined,
                treeData,
                defaultExpandedKeys: [...newExpandedKeys, props.value]
            })
            didUnmounted = true
            setLoading(false)
        }
        if (props.options && props.options.length) {
            setLoading(false)
            didUnmounted = true
            return
        }
        (loading || props.refresh) && !props.treeData && getTreeData()
        return () => {
            didUnmounted = true
        }
    }, [props, loading])
    const getTreeNode = (tree, params = { children: 'nodes', name: 'name', id: 'id', label: 'label' }) => {
        const { children, name, id, label } = params
        return tree.map(el => {
            return <TreeNode value={el[id]} disabled={disabledId === el[id]} label={el[label]} key={el[id]} title={el[name]}>
                {
                    el[children] ? getTreeNode(el[children]) : null
                }
            </TreeNode>
        })
    }
    const { style, select, refresh, disabledId, ...parentProps } = props
    return (
        <div style={{ ...style }}>
            {((!loading && !refresh) || props.treeData) ? select ?
                <TreeSelect
                    {...parentProps}
                    treeDefaultExpandedKeys={state.defaultExpandedKeys}
                    treeDefaultExpandAll={true}
                    onSelect={onSelect}
                    placeholder="请选择部门"
                    value={props.value}
                    treeNodeLabelProp="label"
                    // defaultActiveFirstOption={true}
                    showSearch
                    filterTreeNode={(input, option) => {
                        return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    allowClear
                    treeLine={true}>
                    {
                        getTreeNode(state.treeData)
                    }
                </TreeSelect> : (props.treeData?.length || state.treeData.length) ? <Tree
                    {...parentProps}
                    showLine={true}
                    showIcon={false}
                    fieldNames={{
                        title: 'name',
                        key: 'id',
                        children: 'nodes',
                        ...props.fieldNames
                    }}
                    treeDefaultExpandAll={true}
                    defaultExpandedKeys={state.defaultExpandedKeys}
                    value={props.value}
                    onSelect={onSelect}
                    treeData={props.treeData || state.treeData}
                    {...props}
                    style={null}
                /> : <YzhEmpty /> : !select && <Skeleton active paragraph={{ rows: 20 }} />}
        </div >
    );
};
TreeComponent.propTypes = {
    options: PropTypes.oneOfType([PropTypes.array]),
    value: PropTypes.oneOfType([PropTypes.any]),
    pId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    treeData: PropTypes.oneOfType([PropTypes.any]),
    onSelect: PropTypes.oneOfType([PropTypes.func]),
    fieldNames: PropTypes.oneOfType([PropTypes.object]),
    select: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}
// 类似 shouldComponentUpdate()
function shouldUpdate (prevProps, nextProps) {
    const prevScroll = prevProps.scroll || {}
    const nextprevScroll = nextProps.scroll || {}
    return prevScroll.y !== nextprevScroll.y
}
export default React.memo(TreeComponent, shouldUpdate)