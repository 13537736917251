// 把一个类型下的所有url接口放入这一个文件
import { appUrl } from '@/api/baseUrl'
// let appUrl = 'http://yapi.yunzhonghe.com/mock/162'
// let appUrl = '/local'
let orderUrl = '/orderApi/order'
const goodsprefix = '/shipmentCompanyMapping'
export default {
    addOrEdit: orderUrl + goodsprefix + '/save', // 承运商对应列表新增编辑
    queryByPage: orderUrl + goodsprefix + '/page', // 承运商对应列表
    kuaidi100ShipmentList: orderUrl + '/shipmentCompany/shipmentList/kuaidi100ShipmentList' // 承运商下拉框
}
