import React from 'react'
import {
    Progress,
    Modal,
    notification,
    message
    // Tag, Space,
    // Menu, Dropdown
} from 'antd';
import api from '@/api/apiList'
import PropTypes from 'prop-types';
import './style.scss'
class YzhProgressDialog extends React.Component {
    state = {
        progressStatus: 'active',
        percentage: 0,
        subText: this.props.text || '导出',
        identifyKey: this.props.identifyKey || '',
        loadingApi: this.props.loadingApi || 'loadingProgressAuthskip',
        isSuccessImport: false,
        dialogType: this.props.dialogType || '',
        dialogProgressVisible: this.props.dialogProgressVisible || false
    }

    //如果条件不存在必须要返回null
    static getDerivedStateFromProps (props, current_state) {
        if (props.identifyKey !== current_state.identifyKey) {
            return {
                identifyKey: props.identifyKey,
            }
        }
        return null
    }
    openNotification = (key = '1', percent) => {
        let message = '正在' + this.props.text + '，请稍后...'
        let type = 'warning'
        let status = 'exception'
        if (percent === 100) {
            message = '处理完成'
            type = 'success'
            status = type
        }
        const args = {
            message,
            key,
            top: 100,
            description: <Progress percent={percent} size="small" status={status} />,
            duration: 0,
        };
        notification[type](args);
    }
    initdata () {
        this.setState({
            progressStatus: 'active',
            percentage: 0,
            subText: this.props.text || '导出',
            isSuccessImport: false,
            dialogProgressVisible: true
        })
        if (this.props.dialogType === 'notification') {
            this.openNotification('1', this.state.identifyKey)
        }
    }
    handleOk = () => {
        this.setState({ dialogProgressVisible: false });
    }
    handleCancel = () => {
        this.setState({ dialogProgressVisible: false });
    }
    start (call) {
        this.initdata()
        this.handleExport()
        let timer = setInterval(a => {
            this.handleExport((exportResult) => {
                clearInterval(timer)
                setTimeout(() => {
                    this.setState({ dialogProgressVisible: false });
                    if (exportResult.result !== null) {
                        this.props.onSuccess && this.props.onSuccess(exportResult)
                        exportResult.result.msgLevel !== 2 && message.open({
                            content: (exportResult.result && exportResult.result.message) || exportResult.message || '操作失败！',
                            type: exportResult.success && exportResult.result && exportResult.result.normal ? 'success' : 'error'
                        })
                        if (this.state.percentage === 100 &&
                            exportResult.success &&
                            exportResult.result.msgLevel !== 2 &&
                            exportResult.result.fileUrl) {
                            window.location.href = exportResult.result.fileUrl
                        }
                        if (call && call instanceof Function) {
                            call(exportResult)
                            return
                        }
                    } else {
                        if (call && call instanceof Function) {
                            call(exportResult)
                            return
                        }
                        message.error(exportResult.message)
                    }
                }, 1000)
            })
        }, 1000)
    }
    async handleExport (call) {
        this.setState({ dialogProgressVisible: true });
        try {
            let exportResult = await api.common[this.state.loadingApi]({
                identifyKey: this.state.identifyKey
            })
            if (!exportResult.result) {
                call && call(exportResult)
                return
            }
            if (exportResult.success && exportResult.result !== null) {
                if (!exportResult.result.normal) {
                    call && call(exportResult)
                    return false
                }
            }
            this.setState({
                percentage: exportResult.result.processingValue
            })
            // this.state.percentage = exportResult.result.processingValue
            if (this.props.dialogType === 'notification') {
                this.openNotification('1', this.state.percentage)
            }
            if (this.state.percentage === 100) {
                this.setState({
                    percentage: exportResult.result.processingValue,
                    progressStatus: 'success',
                    isSuccessImport: true
                });
                call && call(exportResult)
            }
        } catch (error) {
            call && call({})
            throw (error)
        }
    }
    render () {
        const {
            dialogProgressVisible,
            isSuccessImport,
            subText,
            percentage,
            progressStatus
        } = this.state
        return <div>
            <Modal
                footer={false}
                maskClosable={false}
                centered
                title="温馨提示"
                width={300}
                bodyStyle={{
                    minHeight: 'auto'
                }}
                wrapClassName="yzh-progress-modal"
                visible={dialogProgressVisible && this.props.dialogType !== 'notification'}
                onOk={this.handleOk}
                onCancel={this.handleCancel}>
                <Progress percent={percentage} status={progressStatus} />
                <p className="txt">
                    {isSuccessImport ? (subText + '成功！') : `正在${subText}，请稍后...`}
                </p>
            </Modal>
        </div>
    }
}
YzhProgressDialog.propTypes = {
    loadingApi: PropTypes.string,
    text: PropTypes.string,
    identifyKey: PropTypes.string,
    dialogType: PropTypes.string,
    dialogProgressVisible: PropTypes.bool,
};
export default YzhProgressDialog
