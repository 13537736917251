// 有些接口需要在header中添加token或是其他，可以按如下配置
import axios from '@/api/axios'
import urls from './urls'
const header = {
    'Content-Type': 'multipart/form-data'
}
import { permissionCodes as codes } from '@/routes/permission'
export default {
    header,
    list (params, code) {
        return axios.post(urls.list, params, {
            headers: {
                OperationCode: code || codes.admin.category.list
            }
        })
    },
    delete (params, code) {
        return axios.post(urls.delete, params, {
            headers: {
                OperationCode: code || codes.admin.category.delete
            }
        })
    },
    deleteMore (params, code) {
        return axios.post(urls.deleteMore, params, {
            headers: {
                OperationCode: code || codes.admin.category.delete
            }
        })
    },
    add (params, code) {
        return axios.post(urls.add, params, {
            headers: {
                OperationCode: code || codes.admin.category.add
            }
        })
    },
    change (params, code) {
        return axios.post(urls.change, params, {
            headers: {
                OperationCode: code || codes.admin.category.exchange
            }
        })
    },
    export (params, code) {
        return axios.post(urls.export, params, {
            headers: {
                OperationCode: code || codes.admin.category.export
            }
        })
    },
    exportCategoryAdminErr (params, code) {
        return axios.get(urls.exportCategoryAdminErr, {
            params,
            headers: {
                OperationCode: code || codes.admin.category.list
            }
        })
    },
}
