import {
    Image
} from 'antd';
// import noDataImage from '@/assets/images/img-null.png'
import noDataImage from '@/assets/images/icon_kong_tu@2x.png'

import PropTypes from 'prop-types';
const YzhEmpty = (props) => {
    return (
        <div
            style={{ textAlign: 'center', ...props.style }}
            className={props.className ? 'ant-empty ' + props.className : 'ant-empty'}>
            <div className="ant-empty-image">
                <Image preview={false}
                    width={props.width || 100}
                    height={props.height || 100}
                    src={props.img || noDataImage}
                // style={{
                //     filter: 'grayscale(0.5)'
                // }}
                />
            </div>
            {props.text !== null ? <p>{props.text || '暂无数据~'}</p> : null}
        </div>
    )
};
YzhEmpty.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.number]),
    img: PropTypes.oneOfType([PropTypes.string]),
}
export default YzhEmpty