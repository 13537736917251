import { createStore, applyMiddleware, combineReducers } from 'redux'
// import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { themeState } from './theme'
import { appState } from './app'

const store = createStore(
    combineReducers({ theme: themeState, app: appState }),
    applyMiddleware(
        // logger,
        thunk
    )
)
export default store