/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'
import { storage } from '@/utils/storage'
import { network } from '@/config'
import Cookies from 'js-cookie'
import routes from '@/routes'
// import NProgress from 'nprogress'
// import { createBrowserHistory } from 'history';
// const history = createBrowserHistory();
import { message } from 'antd'
import { YzhLoading } from '@com'
import { baseUrl } from '@/api/baseUrl'
const { requestTimeout, contentType } = network
// NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false });
const errorHandle = (status, response) => {
    // 401 未登陆
    // 403 token过期
    switch (status) {
        case 401:
            console.log('接口权限异常')
            message.error('接口权限异常')
            break
        // 500 服务器错误
        case 403:
            console.log('身份认证失败，请重新登录！')
            // message.error('身份认证失败，请重新登录！')
            window.location.href = '/login'
            Cookies.remove('token')
            storage.remove('userInfo')
            storage.set('tabList', routes.filter(el => el.affix).map(el => ({
                key: el.path,
                title: el.meta.title,
                closable: !el.affix,
                meta: el.meta
            })))
            break
        // 500 服务器错误
        case 500:
        case 503:
            // router.push({ name: 'error_500' })
            message.error('服务异常,请稍后尝试')
            break
        // 404 请求不存在
        case 404:
            console.log('404 请求不存在', response)
            message.error(response.data.path + '请求地址不存在！')
            break
        // 其它错误，直接抛出错误提示
        default:
            console.log('其它错误', response)
            message.error('请求异常，请稍后重试！')
            break
    }
    return true
}

let loadingInstance = new YzhLoading()
let service = axios.create({
    timeout: requestTimeout || 30000,
})

let showMessage = true

// 设置 post、put 默认 Content-Type
service.defaults.headers.post['Content-Type'] = contentType
service.defaults.headers.put['Content-Type'] = contentType
// 添加请求拦截器

// console.log('添加请求拦截器config', router)
// console.log('---------------------------添加请求拦截器config------------------')

service.interceptors.request.use(
    (config) => {
        // 请求遮罩层
        showMessage = true
        let options = config.data || config.params
        options && (showMessage = options.showMessage !== undefined ? !!options.showMessage : true)
        if ((config.data && config.data.loading) || (config.params && config.params.loading)) {
            loadingInstance.open({ tip: options.tip || options.text })
        }
        // NProgress.start() // 显示进度条
        if ((config.method === 'post' || config.method === 'put') && config.headers['Content-Type'] === contentType) {
            // post、put 提交时，将对象转换为string, 为处理Java后台解析问题
            config.data = JSON.stringify(config.data)
        }
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        const token = storage.get('token', 'cookie')
        token && (config.headers.Authorization = 'Bearer ' + token)
        let OperationCode = storage.get('OperationCode')
        if (config.params && config.params.token) {
            OperationCode = 'user_center'
        }
        // if (!config.url.includes('http') && !config.url.includes('devServe')) {
        if (!config.url.includes('http') && !config.url.includes('devServe') && !config.url.includes('htServe')) { // 测试用，记得删除
            config.url = baseUrl + config.url
            // console.log(config)
        }
        // console.log(config, 'config');
        !config.headers.OperationCode && OperationCode && (config.headers.OperationCode = OperationCode)
        // console.log('添加请求拦截器config', config)
        return config
    },
    (error) => {
        // 请求错误处理
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        // 请求成功
        // console.log('请求成功', response)
        loadingInstance.close()
        // NProgress.done() // 显示进度条
        if (response.headers['authorization']) {
            storage.set('token', response.headers['authorization'], 'cookie')
        }
        if (response.status === 200) {
            if (response.data && !response.data.success) {
                response.data.message && showMessage && message.error(response.data.message)
            }
            return Promise.resolve(response.data)
        } else {
            return Promise.reject(response.data)
        }
    },
    (error) => {
        // 请求失败
        const {
            response
        } = error
        loadingInstance.close()
        if (response) {
            if (!errorHandle(response.status, response)) {
                return Promise.reject(response)
            }
        } else {
            // message.error('接口无响应，请重试')
            console.log('接口无响应****', error)
            // window.location.href = '/login'
            // Cookies.remove('token')
            // storage.remove('userInfo')
            // storage.set('tabList', routes.filter(el => el.affix).map(el => ({
            //     key: el.path,
            //     title: el.meta.title,
            //     closable: !el.affix,
            //     meta: el.meta
            // })))
        }
    }
)

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default service
