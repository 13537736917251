// 有些接口需要在header中添加token或是其他，可以按如下配置
import axios from '@/api/axios'
import urls from './urls'
import carrier from './carrier'
import customerAdmin from './customerAdmin'
import serviceProvider from './serviceProvider'
import supplierAdmin from './supplierAdmin'
import categoryAdmin from './categoryAdmin'

const header = {
    'Content-Type': 'multipart/form-data'
}

export default {
    header,
    // 客户承运商
    customerCarrierList (data, code) {
        return axios.post(urls.customerCarrierList, data, {
            headers: {
                OperationCode: code || 'shipment_customer_order_list'
            }
        })
    },
    isHasCarrier (data, code) {
        return axios.post(urls.isHasCarrier, data, {
            headers: {
                OperationCode: code || 'shipment_customer_order_list'
            }
        })
    },
    carrierList (data, code) {
        return axios.get(urls.carrierList, {
            params: data,
            headers: {
                OperationCode: code || ''
            }
        })
    },
    detachCarrier (data, code) {
        return axios.post(urls.detachCarrier, data, {
            headers: {
                OperationCode: code || 'shipment_customer_order_update'
            }
        })
    },
    exportCarrier (params, code) {
        return axios.post(urls.exportCarrier, params, {
            headers: {
                OperationCode: code || 'shipment_customer_order_exprot'
            }
        })
    },
    changeCarrier (params, code) {
        return axios.post(urls.changeCarrier, params, {
            headers: {
                OperationCode: code || 'shipment_customer_order_update'
            }
        })
    },
    // 多结算体列表查询
    settlemntCustomerList (params, code) {
        return axios.post(urls.settlemntCustomerList, params, {
            headers: {
                OperationCode: code || 'customer_invoice_order_list'
            }
        })
    },
    customerAllList (data, code) {
        return axios.get(urls.customerAllList, {
            params: data,
            headers: {
                OperationCode: code || ''
            }
        })
    },
    customer (data, code) {
        return axios.get(urls.customer, {
            params: data,
            headers: {
                OperationCode: code || ''
            }
        })
    },
    exportSettleMentCustomers (data, code) {
        return axios.post(urls.exportSettleMentCustomers, data, {
            headers: {
                OperationCode: code || 'customer_invoice_order_exprot'
            }
        })
    },
    deleteCustomerInvoice (data, code) {
        return axios.post(urls.deleteCustomerInvoice, data, {
            headers: {
                OperationCode: code || 'customer_invoice_order_update'
            }
        })
    },
    // 导入多结算体客户
    http: axios,
    importCustomerInvoice: {
        url: urls.importCustomerInvoice
    },
    getGoodsNameByErpCode (data, code) {
        // return axios.post(urls.getGoodsNameByErpCode, params, {
        //     headers: {
        //         OperationCode: code || 'leading_service_provider_list'
        //     }
        // })
        return axios.get(urls.getGoodsNameByErpCode, {
            params: data,
            headers: {
                OperationCode: code || 'leading_service_provider_list'
            }
        })
    },
    websiteComboBox (params, code) {
        return axios.post(urls.websiteComboBox, params, {
            headers: {
                OperationCode: code || 'leading_service_provider_list'
            }
        })
    },
    supplier (params, code) {
        return axios.post(urls.supplier, params, {
            headers: {
                OperationCode: code || 'leading_service_provider_list'
            }
        })
    },
    carrier,
    customerAdmin,
    serviceProvider,
    supplierAdmin,
    categoryAdmin,
}
