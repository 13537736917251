import baseUrl, { queryFileUrl } from '../baseUrl'
const prefix = '/goods/file/upload/image'
const path = baseUrl + prefix
const urls = {
    img: path + '/other',
    spuSku: path + '/spuSku',
    good: path + '/good',
    attach: baseUrl + '/goods/file/upload/attach',
    zip: path + '/zip',
    yzhFile: queryFileUrl + '/file/yzh/file/upload'
}
export default urls
