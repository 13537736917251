import React from 'react';
import { Menu, Layout } from 'antd';
import { apps } from '@/micro-app'
import logo from '@/assets/images/logo.png';
import minLogo from '@/assets/images/logo-mini.png';
// import MallOutlined from '@/assets/svg/mall.svg';
// import GoodsOutlined from '@/assets/svg/goods.svg';

import {
    AppstoreOutlined,
    ShopOutlined,
    TeamOutlined,
    CreditCardOutlined,
    SettingOutlined,
    ShoppingFilled,
    ReconciliationOutlined,
    UserOutlined,
    HomeOutlined,
    UsergroupAddOutlined,
    AppstoreAddOutlined,
    ShoppingTwoTone,
    Icon
} from '@ant-design/icons';
import { connect } from 'react-redux'
import { matchRouteByTree, setTreeProps } from '@/utils'
import { hasPermission } from '@/routes/permission'
import config from '@/config'
// import { Link } from 'react-router-dom';    ShopOutlined,
const { Sider } = Layout;
// const { SubMenu } = Menu;
const icons = [{
    path: '/',
    icon: <HomeOutlined />
}, {
    path: "/userManage",
    icon: <SettingOutlined />
}, {
    path: "/userManage/userCenter",
    icon: <UserOutlined />
}, {
    path: "/userManage/salesmanSetting",
    icon: <UsergroupAddOutlined />
}, {
    path: "/userManage/appManage",
    icon: <AppstoreAddOutlined />
},  {
    path: "/userManage/chat",
    icon: <AppstoreAddOutlined />
}, {
    path: "/userManage/logCenter",
    icon: <ReconciliationOutlined />,
}, {
    path: "operation_goods",
    icon: <ShoppingFilled />
}, {
    path: "purchase_manage",
    icon: <ShopOutlined />
}, {
    path: "after_sale_order_manager",
    icon: <ShopOutlined />
},
{
    path: "operation_website",
    icon: <ShopOutlined />
}, {
    path: "operation_website",
    icon: <ShopOutlined />
},
{
    path: "operation_customer",
    icon: <TeamOutlined />,
}, {
    path: "operation_card",
    icon: <CreditCardOutlined />,
}]
class Aside extends React.Component {
    state = {
        currentRoute: '',
        activeKey: this.props.activeKey || '/',
        selectedKeys: this.props.selectedKeys.length ? this.props.defaultSelectedKeys : this.props.defaultSelectedKeys,
        openKeys: this.props.openKeys,
        defaultSelectedKeys: this.props.defaultSelectedKeys,
        defaultOpenKeys: this.props.defaultOpenKeys,
        collapsed: this.props.collapsed
    }
    onOpenChange = (openKeys) => {
        // if (!openKeys.length && this.props.collapsed) return
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.props.routes.map(el => el.path).indexOf(latestOpenKey) === -1) {
            this.props.changeTabs({
                ...this.state,
                openKeys,
                isOpen: true
            })
        } else {
            // console.log(openKeys,  this.props.changeTabs, 'onOpenChange');
            this.props.changeTabs({
                ...this.state,
                openKeys: latestOpenKey ? [latestOpenKey] : [],
                isOpen: true
            })
        }
    }
    onMenuClick = ({ key, keyPath, ...others }) => {
        const { code, pcode } = others.item.props

        let { currentRoute } = matchRouteByTree([...this.props.routes], key, { children: 'children' })
        if (this.props.activeKey === key || !currentRoute.meta) return
        this.setState({
            ...this.state,
            currentRoute,
            activeKey: key
        })
        this.props.addTabs({
            activeKey: key,
            selectedKeys: [key],
            openKeys: keyPath[keyPath.length - 1],
            title: currentRoute.meta.title,
            code,
            pcode,
            defaultSelectedKeys: [key], defaultOpenKeys: keyPath[keyPath.length - 1]
        }, this.props.history)

        return false
    }
    getItems = (routes, apps) => {
        // console.log(routes, 'routes')
      let items = [...setTreeProps(routes, (el, index) => ({...el, icon: el.base ? el.type === 1001 ? apps[el.code.split('_')[0]]?.icon || <AppstoreOutlined /> :  el.type === 1002 ? icons.filter(val => val.path === el.path)[0]?.icon || el.icon || '' : '' : icons.filter(val => val.path === el.path)[0]?.icon || el.icon || '' })).tree]
    //   console.log(items, 'items')
      return items
    }
    //如果条件不存在必须要返回null
    static getDerivedStateFromProps (props, current_state) {
        if (props.openKeys !== current_state.openKeys) {
            return {
                activeKey: props.activeKey,
                openKeys: props.openKeys
            }
        }
        return null
    }
    render () {
        const {
            defaultSelectedKeys,
            defaultOpenKeys,
            selectedKeys,
            activeKey,
            collapsed
        } = this.props
        const { openKeys } = this.state
       return (
            <Sider width="230px" trigger={null} className="yzh-aside" collapsible collapsed={collapsed}>
                {/* <div className="logo" /> */}
                <div className={`logo-content ${collapsed && 'mini'}`}
                    onClick={() => this.props.onLogoClick('/', this.history)}>
                    <img src={collapsed ? minLogo : logo} style={{height: collapsed ? '20px' : '42px'}} className="logo" alt="logo" />
                    {/* {<h3 className={`title ${collapsed && 'hidden'}`}>{config.setting.title}</h3>} */}
                    {/* <div className="medal-light" style={{ height: collapsed && 64 }}>
                        <i style={{ height: collapsed && 64 }}></i>
                    </div> */}
                </div>
                <Menu
                // defaultOpenKeys={!collapsed ? [...defaultOpenKeys, ...openKeys] : []}
                // inlineCollapsed={collapsed}
                // triggerSubMenuAction={'click'}
                // breakpoint="md"
                className="yzh-aside-menu"
                defaultOpenKeys={[...defaultOpenKeys, ...openKeys]}
                defaultSelectedKeys={defaultSelectedKeys}
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                inlineIndent="20"
                mode="inline"
                theme="dark"
                onOpenChange={this.onOpenChange}
                onSelect={this.onMenuClick}
                items={this.getItems(JSON.parse(JSON.stringify(this.props.routes)), apps)} />
            </Sider>
        );
    }
}
export default Aside