import { apps, getFullLink } from '@/micro-app'
import routes from '@/routes'
import { storage } from '@/utils/storage'
import { matchRouteByTree, searchToObject, parseQueryToString } from '@/utils'
import { createBrowserHistory } from 'history'
const history = createBrowserHistory();
const getOpenKeys = (path) => {
    path = path || history.location.pathname
    let openKeys = '/' +  path.split('/')[1]
    for (const key in apps) {
        if (path.includes(apps[key].baseRoute)) {
            openKeys = apps[key].code
        }
    }
    return [openKeys]
}
const getPanes = (data) => {
    let { activeKey, panes, title, code, pcode, remoteRoutes, query } = data
    let exist = panes.filter(el => el.key === activeKey)
    let { currentRoute } = matchRouteByTree([...routes, ...remoteRoutes].filter((item, index, self) => self.findIndex(el => el.path == item.path) === index), activeKey, {children: 'children'})
    let activeIndex = panes.findIndex(el => el.isActive) > -1 ?  panes.findIndex(el => el.isActive) : panes.length - 1
    title = title || currentRoute.meta?.title || ''
    if (!exist.length && title) {
        panes.splice(activeIndex + 1, 0, {
            title,
            key: activeKey, query,
            meta: currentRoute.meta, code, pcode,
            isActive: true
        })
    } else {
        panes = panes.map(el => el.key === activeKey ? {...el, query, code, pcode } : el)
    }
    return panes
}
const getAliveApps = (data) => {
    let { activeKey, aliveApps, title, code, pcode, path } = data
    let base = apps[activeKey.split('/').filter(el => el)[0]]
    if (base) {
        if (aliveApps.map(val => val.path).includes(activeKey)) {
            aliveApps.map(el => {
                if (el.path === activeKey) {
                    el.url = getFullLink(base.url, path)
                    el.timestamp = new Date().getTime()
                }
                return el
            })
        } else {
            let url = getFullLink(base.url, path)
            aliveApps.push({path: activeKey, url, title, code, pcode, timestamp: new Date().getTime()})
        }
    }
    aliveApps = aliveApps.filter((el, index, self) => self.findIndex(val => val.path === el.path) === index)
    return aliveApps
}
const getDefaultState = () => {
    let tabList = storage.get('tabList')
    let aliveApps = storage.get('aliveApps')
    let userInfo = storage.get('userInfo')
    tabList = tabList && JSON.stringify(tabList) !== '{}' ? tabList : [
        { title: '首页', key: '/', meta: { roles: ['user_index'] }, closable: false }
    ]
    let keys = tabList.filter(el => el.isActive).map(el => el.key)
    aliveApps = aliveApps && JSON.stringify(tabList) !== '{}' ? aliveApps.filter(el => keys.includes(el.path)) : []
    let pathname = history.location.pathname
    // let { activeKey, panes, title, code, pcode, remoteRoutes, query } = data
    if (pathname !== '/' && userInfo) {
        aliveApps = getAliveApps({ activeKey: pathname, aliveApps, path: pathname + history.location.search })
        tabList = getPanes({
            activeKey: pathname,
            panes: tabList,
            remoteRoutes: (userInfo && userInfo.routes) || [],
            query: searchToObject(history.location.search)
        })
    }
    return {
        aliveApps,
        userInfo: userInfo || [],
        openKeys: getOpenKeys(),
        routes: (userInfo && userInfo.routes) || [],
        panes: tabList,
        activeKey: history.location.pathname || '/',
        permissionCodes: userInfo && userInfo !== null ? userInfo.permissionCodes : []
    }
}
export { getOpenKeys, getPanes, getAliveApps, getDefaultState }