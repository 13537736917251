import {
    ADD_TABS,
    REMOVE_TABS,
    CHANGE_TABS,
    FILTER_TABS,
    SET_USERINFO,
} from './actionTypes'
import { createBrowserHistory } from 'history'
import {
    addTabs,
    removeTabs,
    changeTabs,
    filterTabs,
    setUserInfo,
    logout
} from './actionCreators'
import { getDefaultState } from './tools'
const history = createBrowserHistory();

const defaultState = {
    keyPath: '/',
    defaultSelectedKeys: [],
    defaultOpenKeys: [],
    selectedKeys: '',
    ...getDefaultState(),
    history
}
const appState = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_TABS:
            return {
                ...state,
                ...addTabs(state, action)
            }
        case REMOVE_TABS:
            return {
                ...state,
                ...removeTabs(state, action)
            }
        case CHANGE_TABS:
            return {
                ...state,
                ...changeTabs(state, action)
            }
        case FILTER_TABS:
            return {
                ...state,
                ...filterTabs(state, action)
            }
        case SET_USERINFO:
            return {
                ...state,
                ...setUserInfo(state, action)
            }
        case 'logout':
            return {
                ...state,
                ...logout(state, action)
            }
        default:
            return state
    }
}
export {
    appState
}