import ReactDOM from 'react-dom';
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { antConfig } from '@/config'
const { loadingConfig } = antConfig
import PropTypes from 'prop-types';
class LoadingInstance {
    constructor(props) {
        this.options = {
            ...loadingConfig,
            ...props
        }
    }
    // 显示loading
    open = (options) => {
        const { style, ...params } = this.options
        let dom = document.createElement('div')
        dom.setAttribute('id', 'yzh-loading')
        style && dom.setAttribute('style', style)
        const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />
        document.body.appendChild(dom)
        ReactDOM.render(<Spin {...params} indicator={antIcon} tip={options.tip || loadingConfig.tip} />, dom)
    }

    // 隐藏loading
    close = () => {
        let loadingDom = document.body.querySelector('#yzh-loading')
        if (document.body.querySelector('#yzh-loading') !== null) {
            loadingDom.remove()
        }
    }
}
LoadingInstance.propTypes = {
    tip: PropTypes.oneOfType([PropTypes.string]),
    style: PropTypes.oneOfType([PropTypes.object]),
}
export default LoadingInstance