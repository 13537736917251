import { asyncLoadComponent } from '@/routes/asyncLoadComponent'
import {
    UserAddOutlined,
    AuditOutlined,
    ReconciliationOutlined,
} from '@ant-design/icons';
import {permissionCodes as codes} from "../permission";
let route = {
    path: '/userManage/logCenter',
    exact: false,
    auth: true,
    component: asyncLoadComponent(() => import('@/pages/logCenter')),
    meta: {
        title: '日志管理',
        roles: [codes.logCenter.manage],
        icon: <ReconciliationOutlined />
    },
    children: [{
        path: '/userManage/logCenter/list',
        exact: true,
        auth: true,
        // keepAlive: true,
        component: asyncLoadComponent(() => import('@/pages/logCenter/list')),
        meta: {
            title: '日志列表',
            roles: [codes.logCenter.list],
            icon: <AuditOutlined />
        },
    },
    {
        path: '/userManage/logCenter/configPage',
        exact: true,
        auth: true,
        // keepAlive: true,
        component: asyncLoadComponent(() => import('@/pages/logCenter/configManage/configPage')),
        meta: {
            title: '配置文件',
            roles: [codes.logCenter.config],
            icon: <UserAddOutlined />
        },
    }]
}
export default route