import axios from '@/api/axios'
import urls from './urls'
import { permissionCodes as codes } from '@/routes/permission'
export const login = (data) => {
    // return出去了一个promise
    return axios.post(urls.login, data)
}
export const msgCode = (params) => {
    return axios.get(urls.msgCode, {
        params
    })
}
export const getMenuByToken = ({ token, userId, appCode }) => {
    return axios.get(urls.getMenuByToken, {
        params: { token, userId, appCode: appCode,
            loading: true, tip: '登录中，请稍候...' }
    })
}

export const getUserInfo = (token) => {
    return axios.get(urls.userInfo, {
        params: token,
        headers: {
            OperationCode: codes.user.edit
        }
    })
}

export const currentUserInfo = (token) => {
    return axios.get(urls.currentUserInfo, {
        params: token,
        headers: {
            OperationCode: codes.user_center
        }
    })
}


export const logout = (data) => {
    if (data && data.isLogout) return Promise.resolve({ data: { success: true } })
    return axios.post(urls.logout, data, {
        headers: {
            OperationCode: codes.user_center
        }
    })
}

export const list = (params) => {
    return axios.get(urls.list, {
        params, headers: {
            OperationCode: codes.user.list
        }
    })
}
export const add = (params) => {
    return axios.post(urls.add, params, {
        headers: {
            OperationCode: codes.user.add
        }
    })
}
export const edit = (params, code) => {
    return axios.post(urls.edit, params, {
        headers: {
            OperationCode: code || codes.user.edit
        }
    })
}
export const remove = (params) => {
    return axios.post(urls.remove, params, {
        headers: {
            OperationCode: codes.user.del
        }
    })
}
export const setRole = (params) => {
    return axios.post(urls.setRole, params, {
        headers: {
            OperationCode: codes.user.Assign_roles
        }
    })
}
export const resetPwd = (params) => {
    return axios.post(urls.resetPwd, params, {
        headers: {
            OperationCode: codes.user.reset_password
        }
    })
}
export const changePwd = (params) => {
    return axios.post(urls.changePwd, params, {
        headers: {
            OperationCode: codes.user_center
        }
    })
}
export const unfreeze = (params) => {
    return axios.post(urls.unfreeze, params, {
        headers: {
            OperationCode: codes.user.status
        }
    })
}
export const freeze = (params) => {
    return axios.post(urls.freeze, params, {
        headers: {
            OperationCode: codes.user.status
        }
    })
}
export const editByCenter = (params) => {
    return axios.post(urls.editByCenter, params, {
        headers: {
            OperationCode: codes.user_center
        }
    })
}

const userCenterApi = {
    login,
    list,
    add,
    edit,
    remove,
    setRole,
    resetPwd,
    changePwd,
    unfreeze,
    freeze,
    getMenuByToken,
    getUserInfo,
    currentUserInfo,
    logout,
    msgCode,
    editByCenter
}
export default userCenterApi
