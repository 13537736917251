import {
    Tabs,
    Breadcrumb,
    Menu, Dropdown
} from 'antd';
import React from 'react';
import {
    DownOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import YzhTabs from '@com/YzhTabs'
import routes from '@/routes'
import { apps } from '@/micro-app'
import { findParentNodes } from '@/utils'
const { TabPane } = Tabs;

class TabsDropdown extends React.Component {
    state = {
        visible: false,
    };

    handleMenuClick = e => {
        this.props.filterTabs(e.key, this.props.history);
        e.key === '3' && this.props.activeKey !== '/' && this.props.history.push('/')
        this.handleVisibleChange(false)
    };

    handleVisibleChange = flag => {
        this.setState({ visible: flag });
    };

    render () {
        const items = [{
            label: '关闭当前标签',
            title: '关闭当前标签',
            key: '1'
        }, {
            label: '关闭其他标签',
            title: '关闭其他标签',
            key: '2'
        }, {
            label: '关闭所有标签',
            title: '关闭所有标签',
            key: '3'
        }]
        const menu = (
            <Menu onClick={this.handleMenuClick} items={items} />
        );
        return (
            <Dropdown
                overlay={menu}
                onVisibleChange={this.handleVisibleChange}
                visible={this.state.visible}
            >
                <div className="dropdown-link" onClick={e => e.preventDefault()}>
                    <DownOutlined />
                </div>
            </Dropdown>
        );
    }
}
class TabsBar extends React.Component {
    constructor(props) {
        super(props);
        this.history = this.props.history
        this.state = {
            activeKey: this.history.location.pathname,
            panes: this.props.panes,
            layout: this.props.layout || 'vertical' // vertical/inline
        };
    }
    onEdit = (activeKey, action) => {
        // console.log('click', activeKey, action);
        if (!activeKey) return
        action === 'remove' && this.props.removeTabs(activeKey)
        action === 'add' && this.props.addTabs({ activeKey })
    }
    getParentRoute = (routes, path, parentRoute) => {
        if (parentRoute) return parentRoute
        routes.map(el => {
            if (el.path === path) {
                parentRoute = el
                return el
            }
            if (el.children) {
                this.getParentRoute(el.children, path, parentRoute)
            }
        })
        return parentRoute
    }
    handleMenuClick = (e) => {
        if (e.key === '1' || e.key === '2' || e.key === '3') {
            this.props.filterTabs(e.key)
            e.key === '3' && this.props.activeKey !== '/' && this.props.history.push('/')
            return
        }
        this.props.changeTabs({ activeKey: e.key })
    }
    // getOpenKeys = (path, panes) => {
    //     const { code, pcode } = panes.filter(el => el.key === path)[0] || {}
    //     let arr = path.split('/')
    //     let openKeys = code ? (pcode || path) : '/' + arr.slice(1, 3).join('/')
    //     return [openKeys]
    // }
    getOpenKeys = (path, apps) => {
        let openKeys = '/' +  path.split('/')[1]
        for (const key in apps) {
            if (path.includes(apps[key].baseRoute)) {
                openKeys = apps[key].code
            }
        }
        return [openKeys]
    }
    render () {
        const { activeKey = '/', panes = [], changeTabs, filterTabs, layout = 'vertical', className } = this.props
        let parentPath = '/' + activeKey.split('/')[1]
        let parentRoute = this.getParentRoute(routes, parentPath) || { meta: { title: '首页' } }
        let breadcrumbArr = [panes.filter(el => el.key === activeKey)[0] && panes.filter(el => el.key === activeKey)[0].title, parentRoute.meta.title]
        return (
            <React.Fragment>
                {layout === 'inline' && <YzhTabs
                    hideAdd
                    draggable
                    className="yzh-tabs-nav"
                    onChange={(val) => {
                        changeTabs({ activeKey: val, openKeys: this.getOpenKeys(val, apps), history: this.history })
                    }}
                    onEdit={this.onEdit}
                    activeKey={activeKey}
                    type="editable-card"
                    tabBarExtraContent={{
                        right: <TabsDropdown filterTabs={filterTabs} {...this.props}></TabsDropdown>
                    }}
                >
                    {panes.map((pane, index) => (
                        <TabPane tab={pane.title} key={pane.key || index} closable={pane.closable}>
                            {/* {pane.content} */}
                            {this.props.children}
                        </TabPane>
                    ))}
                </YzhTabs>}
                {
                    layout === 'vertical' && <Breadcrumb
                        style={{ lineHeight: '64px' }}
                        className={'yzh-breadcrumb ' + className}>
                        <Breadcrumb.Item
                            overlay={() => <Menu>
                                <Menu.Item key="0" style={{ padding: 0 }}>
                                    <Menu className={'tabs'} selectedKeys={activeKey} style={{ overflow: 'hidden', overflowY: 'auto', maxHeight: 130 }}>
                                        {panes.map(pane => (
                                            <Menu.Item
                                                style={{ lineHeight: '32px', height: 32 }}
                                                onClick={() => this.handleMenuClick(pane)}
                                                key={pane.key} className="clearfix">
                                                <span className="fl">{pane.title}</span>
                                                {pane.key !== '/' && <CloseOutlined
                                                    style={{ lineHeight: '32px' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.onEdit(pane.key, 'remove')
                                                    }}
                                                    className="fr ml10" />}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                </Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key="1" onClick={() => this.handleMenuClick({ key: '1' })}>关闭当前标签</Menu.Item>
                                <Menu.Item key="2" onClick={() => this.handleMenuClick({ key: '2' })}>关闭其他标签</Menu.Item>
                                <Menu.Item key="3" onClick={() => this.handleMenuClick({ key: '3' })}>关闭所有标签</Menu.Item>
                            </Menu>}
                            dropdownProps={{
                                // trigger: 'click',
                                overlayClassName: 'yzh-breadcrumb-dropdown'
                            }}>
                            <a>{breadcrumbArr[0] || '首页'}</a>
                        </Breadcrumb.Item>
                        {parentRoute.path !== activeKey && <Breadcrumb.Item>{breadcrumbArr[1]}</Breadcrumb.Item>}
                        <Breadcrumb.Item>{breadcrumbArr[0]}</Breadcrumb.Item>
                    </Breadcrumb>
                }
            </React.Fragment>
        );
    }
}
export default TabsBar