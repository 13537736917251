import { asyncLoadComponent } from '@/routes/asyncLoadComponent'
import {
    UserAddOutlined,
    AuditOutlined,
    ApartmentOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { permissionCodes as codes } from '../permission'
let route = {
    path: '/userManage/salesmanSetting',
    exact: false,
    auth: true,
    index: 2,
    component: asyncLoadComponent(() => import('@/pages/salesmanSetting')),
    meta: {
        title: '业务员管理',
        roles: ['business_user'],
        icon: <SettingOutlined />
    },
    children: [
    {
        path: '/userManage/salesmanSetting/sales',
        exact: true,
        component: asyncLoadComponent(() => import('@/pages/salesmanSetting/sales')),
        auth: true,
        // pIndex: 2,
        index: 22,
        meta: {
            title: '销售员',
            roles: ['user_sale_admin'],
            icon: <UserAddOutlined />
        },
    },
    {
        path: '/userManage/salesmanSetting/purchase',
        exact: true,
        component: asyncLoadComponent(() => import('@/pages/salesmanSetting/purchase')),
        auth: true,
        // pIndex: 2,
        index: 22,
        meta: {
            title: '采购员',
            roles: ['user_purchase_admin'],
            icon: <UserAddOutlined />
        },
    },
    {
        path: '/userManage/salesmanSetting/category',
        exact: true,
        auth: true,
        // pIndex: 2,
        index: 23,
        component: asyncLoadComponent(() => import('@/pages/salesmanSetting/category')),
        meta: {
            title: '品类经理',
            roles: ['user_manager_admin'],
            icon: <ApartmentOutlined />
        },
    },
    {
        path: '/userManage/salesmanSetting/merchants',
        exact: true,
        component: asyncLoadComponent(() => import('@/pages/salesmanSetting/merchants')),
        auth: true,
        // pIndex: 2,
        index: 22,
        meta: {
            title: '招商人员',
            roles: ['user_attract_admin'],
            icon: <UserAddOutlined />
        },
    },
    {
        path: '/userManage/salesmanSetting/selfSales',
        exact: true,
        auth: true,
        component: asyncLoadComponent(() => import('@/pages/salesmanSetting/selfSale')),
        meta: {
            title: '自营销售',
            roles: ['user_self_admin'],
            icon: <AuditOutlined />
        },
    },
    {
        path: '/userManage/salesmanSetting/selfSales/detail',
        exact: true,
        auth: true,
        component: asyncLoadComponent(() => import('@/pages/salesmanSetting/selfSale/detail')),
        meta: {
            title: '自营销售详情',
            hideInMenu: true,
            roles: ['user_self_admin'],
            icon: <AuditOutlined />
        },
    },
]
}
export default route