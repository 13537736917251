import axios from '@/api/axios'
import urls from './urls'
import { permissionCodes as codes } from '@/routes/permission'
// import baseUrl from '@/api/baseUrl'
// const baseUrl = 'http://yapi.yunzhonghe.com/mock/258'
const log = {
    list (params) {
        return axios.get(urls.list, {
            params, headers: {
                OperationCode: codes.dept.tree
            }
        })
    },
    tree (params, code) {
        return axios.get(urls.tree, {
            params, headers: {
                OperationCode: code || codes.dept.tree
            }
        })
    },
    add (params) {
        return axios.post(urls.add, params, {
            headers: {
                OperationCode: codes.dept.add
            }
        })
    },
    edit (params) {
        return axios.post(urls.edit, params, {
            headers: {
                OperationCode: codes.dept.edit
            }
        })
    },
    remove (params) {
        return axios.post(urls.remove, params, {
            headers: {
                OperationCode: codes.dept.remove
            }
        })
    },
    sort (params) {
        return axios.post(urls.sort, params, {
            headers: {
                OperationCode: codes.dept.sort
            }
        })
    },
    info (params) {
        return axios.get(urls.info, {
            params, headers: {
                OperationCode: codes.dept.edit
            }
        })
    },
    queryCategoryTree (params) {
        return axios.get(urls.queryCategoryTree, {
            params, headers: {
                OperationCode: ''
            }
        })
    },


}
export default log
