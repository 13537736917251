import axios from 'axios'
import urls from './urls'
import baseUrl from '@/api/baseUrl'
import { storage } from '@/utils/storage'
const token = storage.get('token', 'cookie')
const header = {
    'Content-Type': 'multipart/form-data'
}
const upload = {
    baseUrl,
    yzhFileUpload: urls.yzhFile,
    img (data) {
        return axios.post(urls.img, data, { headers: header })
    },
    spuSku (data) {
        return axios.post(urls.spuSku, data, { headers: header })
    },
    good (data) {
        return axios.post(urls.good, data, { headers: header })
    },
    attach (data) {
        return axios.post(urls.attach, data, { headers: header })
    },
    zip (data) {
        return axios.post(urls.zip, data, { headers: header })
    },
    uploadyzhFile (data) {

        header.Authorization = 'Bearer ' + token
        return axios.post(urls.yzhFile, data, { headers: header })
    }
}
export default upload
