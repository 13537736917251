/**
 * @description 导出默认主题配置
 */
const {
    generate,
    presetDarkPalettes
} = require('@ant-design/colors');
const { blue, red, grey } = presetDarkPalettes
const orange = generate('#f8711a')
const dark = generate('#3f3d3c')
const green = generate('#009688')
const black = generate('#333333')
const white = generate('#fff')
const baseColors = [
    {
        primaryColor: orange[5],
        secondColor: black[5],
        hbgColor: white[5],
        bgColor: dark[5]
    }, {
        primaryColor: red.primary,
        secondColor: black[5],
        hbgColor: white[5],
        bgColor: red.primary
    }, {
        primaryColor: red.primary,
        secondColor: black[5],
        hbgColor: red.primary,
        bgColor: red.primary,
    }, {
        primaryColor: red.primary,
        secondColor: black[5],
        hbgColor: white[5],
        logobgColor: red.primary,
        bgColor: dark[5]
    }, {
        primaryColor: orange[5],
        secondColor: black[5],
        hbgColor: dark[5],
        bgColor: dark[5]
    }, {
        primaryColor: blue.primary,
        secondColor: black[5],
        hbgColor: white[5],
        logobgColor: blue.primary,
        bgColor: black[9]
    }, {
        primaryColor: blue.primary,
        secondColor: blue[0],
        hbgColor: blue.primary,
        isDiffer: true,
        bgColor: grey.primary
    }, {
        primaryColor: blue.primary,
        secondColor: blue[0],
        hbgColor: white[5],
        bgColor: blue[2]
    }, {
        primaryColor: orange[5],
        secondColor: black[5],
        hbgColor: white[5],
        bgColor: white[5]
    }, {
        primaryColor: blue[4],
        secondColor: black[5],
        hbgColor: white[5],
        bgColor: blue[1]
    }, {
        primaryColor: green[5],
        secondColor: green[0],
        hbgColor: white[5],
        bgColor: green[5]
    }, {
        primaryColor: green[5],
        secondColor: green[0],
        hbgColor: green[5],
        bgColor: green[5]
    }
]
// const colors = generate('#f8711a');
const theme = {
    //布局种类 horizontal vertical gallery comprehensive common
    defaults: baseColors,
    id: 1,
    theme: baseColors[0],
    // 布局
    layout: 'horizontal',
    //主题名称 default ocean green glory white
    themeName: 'ocean',
    //是否固定头部
    fixedHeader: true,
    //是否显示顶部进度条
    showProgressBar: true,
    //是否显示多标签页
    showTabsBar: true,
    // tabs页签样式
    tabsLayout: 'inline', // vertical, inline： vertical 显示在头部， inline 显示在头部下方
    //是否显示页脚
    showFooter: false,
    //是否显示语言选择组件
    showLanguage: true,
    //是否显示刷新组件
    showRefresh: true,
    //是否显示搜索组件
    showSearch: true,
    //是否显示主题组件
    showTheme: true,
    //是否显示通知组件
    showNotice: true,
    //是否显示全屏组件
    showFullScreen: true,
    // 黑白模式
    isBlackModel: false,
    // 是否缓存页签
    tabsCache: true,
}
export default theme
