import { asyncLoadComponent } from '@/routes/asyncLoadComponent'
import {
    UserOutlined,
    UserAddOutlined,
    AuditOutlined,
    ApartmentOutlined,
} from '@ant-design/icons';
import { permissionCodes as codes } from '../permission'
let route = {
    path: '/userManage/userCenter',
    exact: false,
    auth: true,
    index: 1,
    // keepAlive: true,
    component: asyncLoadComponent(() => import('@/pages/userCenter')),
    meta: {
        title: '用户中心',
        roles: [codes.user.manage],
        icon: <UserOutlined />
    },
    children: [
        {
            path: '/userManage/userCenter/authGroup',
            exact: true,
            index: 13,
            // pIndex: 1,
            // keepAlive: true,
            component: asyncLoadComponent(() => import('@/pages/userCenter/authGroup/index')),
            auth: true,
            meta: {
                title: '权限组配置',
                roles: ['user_group'],
                icon: <UserAddOutlined />
            },
        },
        {
        path: '/userManage/userCenter/roleManage',
        exact: true,
        auth: true,
        index: 11,
        // pIndex: 1,
        // keepAlive: true,
        component: asyncLoadComponent(() => import('@/pages/userCenter/roleManage/index')),
        meta: {
            title: '角色管理',
            roles: [codes.role.list],
            icon: <AuditOutlined />
        },
    },
    {
        path: '/userManage/userCenter/access',
        exact: true,
        auth: true,
        index: 12,
        // pIndex: 1,
        // keepAlive: true,
        component: asyncLoadComponent(() => import('@/pages/userCenter/roleManage/access')),
        meta: {
            title: '权限分配',
            hideInMenu: true,
            roles: [codes.role.authority],
            // roles: ['role_authority'],
            icon: <AuditOutlined />
        },
    },
    {
        path: '/userManage/userCenter/userManage',
        exact: true,
        index: 13,
        // pIndex: 1,
        // keepAlive: true,
        component: asyncLoadComponent(() => import('@/pages/userCenter/userManage/index')),
        auth: true,
        meta: {
            title: '用户管理',
            roles: [codes.user.list],
            icon: <UserAddOutlined />
        },
    },
    {
        path: '/userManage/userCenter/authGroup/data',
        exact: true,
        index: 13,
        // pIndex: 1,
        // keepAlive: true,
        component: asyncLoadComponent(() => import('@/pages/userCenter/authGroup/data')),
        auth: true,
        meta: {
            title: '数据权限组配置',
            roles: ['data_scope_group'],
            icon: <UserAddOutlined />
        },
        },
        {
            path: '/userManage/userCenter/deptMage',
            exact: true,
            component: asyncLoadComponent(() => import('@/pages/userCenter/deptMage/index')),
            auth: true,
            // pIndex: 2,
            index: 22,
            meta: {
                title: '部门管理',
                roles: ['user_dept'],
                icon: <UserAddOutlined />
            },
        },
    {
        path: '/userManage/userCenter/authGroup/member',
        exact: true,
        index: 13,
        // pIndex: 1,
        // keepAlive: true,
        component: asyncLoadComponent(() => import('@/pages/userCenter/authGroup/member')),
        auth: true,
        meta: {
            title: '成员列表',
            hideInMenu: true,
            roles: ['user_data_scope_list'],
            icon: <UserAddOutlined />
        },
    },
    // {
    //     path: '/userManage/userCenter/departManage',
    //     exact: true,
    //     auth: true,
    //     index: 14,
    //     // pIndex: 1,
    //     // keepAlive: true,
    //     component: asyncLoadComponent(() => import('@/pages/userCenter/departManage/index')),
    //     meta: {
    //         title: '部门管理',
    //         roles: [codes.dept.tree],
    //         icon: <ApartmentOutlined />
    //     },
    // },
    {
        path: '/userManage/userCenter/userInfo',
        exact: true,
        auth: true,
        // pIndex: 1,
        component: asyncLoadComponent(() => import('@/pages/userCenter/userManage/userInfo')),
        meta: {
            title: '个人信息',
            hideInMenu: true,
            roles: [codes.user_center],
            icon: <UserAddOutlined />
        }
    },
    {
        path: '/userManage/userCenter/add',
        exact: true,
        auth: true,
        index: 16,
        // pIndex: 1,
        // keepAlive: true,
        component: asyncLoadComponent(() => import('@/pages/userCenter/userManage/edit')),
        meta: {
            title: '新增用户',
            hideInMenu: true,
            roles: [codes.user.add],
            icon: <UserAddOutlined />
        }
    },
    {
        path: '/userManage/userCenter/edit',
        exact: true,
        auth: true,
        index: 17,
        // pIndex: 1,
        component: asyncLoadComponent(() => import('@/pages/userCenter/userManage/edit')),
        meta: {
            title: '编辑用户',
            hideInMenu: true,
            roles: [codes.user.edit],
            icon: <UserAddOutlined />
        }
    },
    {
        path: '/userManage/userCenter/authGroup/importPage',
        exact: true,
        index: 18,
        // pIndex: 1,
        // keepAlive: true,
        component: asyncLoadComponent(() => import('@/pages/userCenter/authGroup/importPage/importPage')),
        auth: true,
        meta: {
            title: '导入供应商',
            hideInMenu: true,
            roles: [''],
            icon: <UserAddOutlined />
        },
    },
    {
        path: '/userManage/userCenter/authGroup/importBrandPage',
        exact: true,
        index: 18,
        // pIndex: 1,
        // keepAlive: true,
        component: asyncLoadComponent(() => import('@/pages/userCenter/authGroup/importPage/importPage')),
        auth: true,
        meta: {
            title: '导入新增品牌',
            hideInMenu: true,
            roles: [''],
            icon: <UserAddOutlined />
        },
    },
]
}
export default route