// 有些接口需要在header中添加token或是其他，可以按如下配置
import axios from '@/api/axios'
import urls from './urls'
const header = {
    'Content-Type': 'multipart/form-data'
}

export default {
    header,
    // 区域供应商订单设置 移除
    deleteServiceProvider (params, code) {
        return axios.post(urls.deleteServiceProvider, params, {
            headers: {
                OperationCode: code || 'leading_service_provider_update'
            }
        })
    },
    // 区域供应商订单设置 导入
    importServiceProvider: {
        url: urls.importServiceProvider,
        headers: {
            OperationCode: 'leading_service_provider_exprot',
            'Content-Type': 'multipart/form-data'
        }
    },
    exportServiceProvider (params, code) {
        return axios.post(urls.exportServiceProvider, params, {
            headers: {
                OperationCode: code || 'leading_service_provider_exprot'
            }
        })
    },
    // 区域供应商订单设置保存
    saveOrUpdateServiceProvider (params, code) {
        return axios.post(urls.saveOrUpdateServiceProvider, params, {
            headers: {
                OperationCode: code || 'leading_service_provider_update'
            }
        })
    },
    // 区域供应商订单设置列表
    serviceProviderSearchPage (params, code) {
        return axios.post(urls.serviceProviderSearchPage, params, {
            headers: {
                OperationCode: code || 'leading_service_provider_list'
            }
        })
    },
    // 区域供应商订单设置结算方式查询
    searchSettlementType (params, code) {
        return axios.post(urls.searchSettlementType, params, {
            headers: {
                OperationCode: code || 'leading_service_provider_list'
            }
        })
    },
    // 区域供应商订单设置结算方式更改
    updateSettlementType (params, code) {
        return axios.post(urls.updateSettlementType, params, {
            headers: {
                OperationCode: code || 'leading_service_provider_update'
            }
        })
    },
    findsupplier (data, code) {
        return axios.get(urls.findsupplier, {
            params: data,
            headers: {
                OperationCode: code || 'leading_service_provider_list'
            }
        })
    },
}
