
import { SketchPicker } from 'react-color';
import React from 'react';
import { debounce } from '@/utils'
import PropTypes from 'prop-types';
export default class ColorSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: props.color,
            key: props.objKey,
            displayColorPicker: "none",
        };
        this.handleClick = this.handleClick.bind(this);
    }
    static getDerivedStateFromProps (nextProps, prevState) {
        const { color } = nextProps;
        // 当传入的type发生变化的时候，更新state
        if (color !== prevState.color) {
            return {
                color
            };
        }
        // 否则，对于state不进行任何操作
        return null;
    }
    hideColorPanel = (e) => {
        let { displayColorPicker, key, color } = this.state;
        let ColorPickerRef = document.querySelector('.color-picker-main')
        if (ColorPickerRef !== null &&
            !ColorPickerRef.contains(e.target) &&
            displayColorPicker === 'block') {
            this.setState({ displayColorPicker: 'none' })
            // this.props.updateColor(key, color)
        }
        e.preventDefault();
    }
    componentDidMount () {
        document.addEventListener('click', this.hideColorPanel)
    }
    componentWillUnmount () {
        document.removeEventListener('click', this.hideColorPanel)
    }
    handleClick = (e) => {
        let { displayColorPicker, key, color } = this.state;
        displayColorPicker = displayColorPicker === "none" ? "block" : "none";
        this.setState({ displayColorPicker })
        e.stopPropagation()
    }
    handleChange = (value) => {
        let color = value.hex;
        this.setState({ color })
        let { key } = this.state;
        this.props.updateColor(key, color)
    }
    render () {
        let { color, displayColorPicker } = this.state;
        return (
            <div className="color-picker" style={{ display: 'inline-block' }}>
                <button className="color-picker-btn" onClick={this.handleClick} style={{ background: color }}></button>
                {/* // button色块样式 */}
                {
                    // 是选择器脱离标准流
                    displayColorPicker === "block" ?
                        <SketchPicker className="color-picker-main" color={color} onChange={this.handleChange} />
                        : null
                }
            </div>
        );
    }
}

ColorSelect.propTypes = {
    color: PropTypes.oneOfType([PropTypes.string]),
    updateColor: PropTypes.oneOfType([PropTypes.func]),
    // objKey: PropTypes.oneOfType([PropTypes.string]),
}