import React from 'react';
import config from '@/config'
import { Select } from 'antd';
import axios from '@/api/axios';
import { appUrl } from '@/api/baseUrl'
import querystring from 'querystring';
import PropTypes from 'prop-types';

const { fieldProps } = config.antConfig.tableConfig.columnConfig;

class YzhSelect extends React.Component {
    static defaultProps = fieldProps;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            source: [],
            isSearch: false,
            searchValue: undefined,
            currentPage: 1,
            params: props.params,
            pageSize: props.params && props.params.pageSize || 20,
            value: props.value || undefined,
        }
    }
    //如果条件不存在必须要返回null
    // static getDerivedStateFromProps (props, current_state) {
    //     console.log(props.params, current_state.params, 'props, current_state');
    //     if (JSON.stringify(props.params) !== JSON.stringify(current_state.params)) {
    //         return {
    //             params: props.params
    //         }
    //     }
    //     return null
    // }
    fetch (value, callback) {
        const {
            props = {
                value: 'value',
                label: 'label',
                disabled: { key: -2, reverse: false }
            },
            params = {},
            pcode,
            options = [],
            request = '/operation/customer/findCustomerByPage'
        } = this.props
        const { key, ...others } = params
        const { disabled } = props
        // console.log(this.props.value, 'value');
        const str = querystring.encode({
            code: 'utf-8',
            ...others,
            [params.key || 'key']: value || '',
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize
        });
        let baseUrl = request.includes('http') ? '' : appUrl
        const url = `${baseUrl}${request}?${str}`
        axios.get(url, {
            headers: {
                OperationCode: pcode
            }
        }).then(d => {
            if (!d || !d.success) {
                return
            }
            if (value === this.state.searchValue) {
                const { records = d.result || [] } = d && d.result || {};
                let data = this.props['request-on-focus'] && this.props.options &&
                    this.props.options.length ?
                    this.props.options.map(r => ({
                        ...r,
                        value: r[this.props.props['value']],
                        label: r[this.props.props['label']],
                    })) : [];
                records.forEach(r => {
                    data.push({
                        ...r,
                        value: r[props['value']],
                        label: r[props['label']],
                    });
                });
                if (!this.state.isSearch) {
                    let dataIds = this.state.data.map(el => el.value)
                    let optionIds = options.map(el => el[props['value']])
                    data = data.filter(el => !dataIds.includes(el.value)).filter(el => !optionIds.includes(el.value))
                    data = [...options.filter(el => !dataIds.includes(el[props['value']])).map(r => ({
                        ...r,
                        value: r[props['value']],
                        label: r[props['label']],
                    })), ...this.state.data, ...data]
                    // data.unshift({ value: -2, label: '全不选' })
                    data = data.filter((item, index, self) => self.findIndex(el => el.value == item.value) === index)
                    data = this.disabledOptions({ data, value: this.state.value, ...disabled })
                }
                if (value) {
                    data = data.filter(el => el[props['label']].includes(value))
                }
                this.props.onChange && this.props.onChange(this.state.value, data)
                this.props.filterOption && (data = this.props.filterOption(data, value))
                callback && callback(data);
            }
        })
    }
    handleSearch = value => {
        this.setState({
            isSearch: true,
            currentPage: 1,
            searchValue: value || undefined
        }, () => {
            if (value) {
                // const { params, props } = this.props
                // if (!params.key) {
                //     this.setState({ searchValue: value })
                //     return
                // }
                this.fetch(value, data => this.setState({ data, searchValue: value }));
            } else {
                this.setState({ searchValue: undefined }, () => {
                    this.fetch(this.state.searchValue, data => this.setState({ data }))
                })
            }
            this.props.onSearch && this.props.onSearch()
        })
    }
    /**
     * 禁用option
     * @param {*} param data: 原始数据 key: 禁用的值， reverse： 是否反选禁用
     * @returns data 修改后的数据
     */
    disabledOptions = ({ data, value, key, reverse }) => {
        if (!value && reverse) return data
        value && data.map(el => {
            if (reverse) {
                if (value.length) {
                    if (value.includes(key)) {
                        el.disabled = !value.includes(el.value)
                    } else {
                        el.disabled = el.value === key
                    }
                } else {
                    el.disabled = false
                }
            } else {
                el.disabled = value.includes(key) || el.value === key
            }
        })
        return data
    }
    handleChange = (value, selectedRow) => {
        const { disabled } = this.props.props || {}
        let data = this.disabledOptions({ data: this.state.data, value, ...disabled })
        this.setState({ data, value });
        this.props.onChange && this.props.onChange(value, selectedRow || this.state.data)
    }
    handleClear  = (value, selectedRow) => {
        console.log(value)
    }
    onPopupScroll = (e) => {
        const { target } = e;
        console.log(target)
        console.log(target.scrollTop, target.offsetHeight === target.scrollHeight)
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                isSearch: false
            }, () => {
                this.fetch(this.state.searchValue, data => this.setState({ data }));
            })
        }
        this.props.onPopupScroll && this.props.onPopupScroll(e)
    }
    componentDidMount () {
        if (this.props['request-on-focus'] || this.props.async) {
            let data = this.props.options &&
                this.props.options.length &&
                this.props.options.map(r => ({
                    value: r[this.props.props['value']],
                    label: r[this.props.props['label']],
                })) || []
            this.setState({ data }, () => { })
        } else {
            setTimeout(() => {
                this.fetch(this.state.searchValue, data => this.setState({ data }))
            }, this.props.delay || 100);
        }
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }
    // // // 因为两次生成的随机数可能相同，如果相同，此时，不需要重新渲染
    // shouldComponentUpdate (nextProps, nextState) {
    //     console.log('最新状态：', nextProps, ', 当前状态：', this.state)
    //     return JSON.stringify(nextProps.params) !== JSON.stringify(this.state.params) || JSON.stringify(nextProps.value) !== JSON.stringify(this.state.value)
    // }
    render () {
        return (
            <Select
                showSearch
                {...this.defaultProps}
                style={this.props.style}
                value={this.state.value}
                {...this.props}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                onClear={this.handleClear}
                onPopupScroll={this.onPopupScroll}
                searchValue={this.state.searchValue}
                onFocus={() => this.props['request-on-focus'] && this.fetch(this.state.searchValue, data => this.setState({ data }))}
                virtual={true}
                filterOption={false}
                options={this.state.data}
            />
        );
    }
}
YzhSelect.propTypes = {
    request: PropTypes.oneOfType([PropTypes.string]), // 请求地址
    params: PropTypes.oneOfType([PropTypes.object]), // 请求参数
    props: PropTypes.oneOfType([PropTypes.object]), // 自定义 value, label 字段
    key: PropTypes.oneOfType([PropTypes.string]), // 查询字段
    // value: propTypes.any, // 选中的值
}
export default YzhSelect
