
import { appUrl, operationCenterUrl } from '@/api/baseUrl'
// const appUrl = '/stockApi'
// const appUrl = 'http://yapi.yunzhonghe.com/mock/250' // 开发url'
const goodsprefix = '/goods'
const orderprefix = '/order'
const urls = {
    // 订单中心，基础通用接口
    orderGetDataAuthskip: appUrl + orderprefix + '/basicData/getData/authskip',
    // 品牌
    brandList: appUrl + goodsprefix + '/brand/getBrandSelectList',
    // 分类
    categoryList: appUrl + goodsprefix + '/category/queryCategoryBaeInfo?parentId=0',
    // 库存中心商品来源
    getSourceFromData: appUrl + goodsprefix + '/stockCenter/getSourceFrom',
    // 进度条
    loadingProgressAuthskip: appUrl + goodsprefix + '/loading/progressBar/authskip',

    getWarehouseList: appUrl + '/stock/warehouse/getWarehouseList/authskip',

    regionData: appUrl + goodsprefix + '/regionalInfo/getRegionalDataAll',
    // 获取导入错误的文件路径
    errorFileAuthskip: appUrl + orderprefix + '/import/errorFile/authskip',
    // 库存导入进度
    stockImportErrorFile: appUrl + '/stock/import/errorFile/authskip',
    // 库存导入错误日志
    stockImportProgressBar: appUrl + '/stock/loading/progressBar/authskip',
    getSalerByDep: `${operationCenterUrl}/erp/saler/getByDep/authskip`, // 获取erp销售员
    departmentCombobox: `${operationCenterUrl}/department/combobox/authskip`, // 获取erp部门数据
    departmentSync: `${operationCenterUrl}/department/sync`, // 获取erp数据
    emplist: `${appUrl}/user/emplist/authskip`,

    userProgressBar: appUrl + '/user/loading/progressBar/authskip', // 用户中心的进度条


}
export default urls
