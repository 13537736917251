import baseUrl from '@/api/baseUrl'
// const baseUrl = '/yy'
// const baseUrl = '/yd'
const prefix = '/mgr'
const userApi = {
    login: `${baseUrl}/login/authskip`, // 登录
    logout: `${baseUrl}/login/logout`, // 登录
    msgCode: `${baseUrl}/login/code/authskip`, // 短信验证码接口
    // userInfo: `${baseUrl}${prefix}/getUserInfo`, // 用户信息
    userInfo: `${baseUrl}${prefix}/getUserByEdit`, // 用户信息
    currentUserInfo: `${baseUrl}${prefix}/currentUserInfo/authskip`, // 用户信息
    list: `${baseUrl}${prefix}/list`, // 列表
    add: `${baseUrl}${prefix}/add`, // 新增
    edit: `${baseUrl}${prefix}/edit`, // 修改
    editByCenter: `${baseUrl}${prefix}/editByCenter/authskip`, // 修改
    remove: `${baseUrl}${prefix}/delete`, // 删除
    setRole: `${baseUrl}${prefix}/setRole`, // 分配角色
    resetPwd: `${baseUrl}${prefix}/reset`, // 重置密码
    changePwd: `${baseUrl}${prefix}/changePwd/authskip`, // 修改密码
    unfreeze: `${baseUrl}${prefix}/unfreeze`, // 解冻
    freeze: `${baseUrl}${prefix}/freeze`, // 冻结
    updateAvatar: `${baseUrl}/updateAvatar`, // 上传头像
    getMenuByToken: `${baseUrl}/queryUserById`
}
export default userApi
