import axios from '@/api/axios'
import urls from './urls'
import { permissionCodes as codes } from '@/routes/permission'
const configPage = {
    list (params, code) {
        return axios.get(urls.list, {
            params, headers: {
                OperationCode: code || codes.logCenter.config
            }
        })
    },
    edit (params, code) {
        return axios.post(urls.edit, params, {
            headers: {
                OperationCode: code || codes.logCenter.config
            }
        })
    },
    cachelist (params, code) {
        return axios.get(urls.cachelist, {
            params, headers: {
                OperationCode: code || codes.logCenter.config
            }
        })
    },
    clear (params, code) {
        return axios.get(urls.clear, {
            params,
            headers: {
                OperationCode: code || codes.logCenter.config
            }
        })
    },
}
export default configPage
