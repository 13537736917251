// 适配ie>9
import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react';
import ReactDOM from 'react-dom';
import 'nprogress/nprogress.css'
import './index.css';
import '@/assets/scss/main.scss';
import '@/assets/less/theme/index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from "react-redux";
import store from './store'
import axios from './api/axios' //引入axois
import apiList from './api/apiList' //引入axois

import { message } from 'antd';
import { antConfig } from '@/config'

// 引入微前端框架 micro-app
// import microApp from '@micro-zoe/micro-app'
import { config } from './micro-app'

// console.log(process.env, 'process.env.MOD_ENV');
// microApp.start({
//     ...config
// })

message.config(antConfig.messageConfig);
React.Component.prototype.axios = axios
React.Component.prototype.$api = apiList
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider >,
    document.getElementById('root')
);
reportWebVitals();
